import React from "react";
import AdminAddForm from "./admin-add-form";
import {AddFormType} from "../../../types/types";
import AdminUpdateForm from "./admin-update-form";

type props = {
    callPopup: (message: string, type: string) => void
}

export default class AdminUpdateContainer extends React.Component<props, any>{

    data: AddFormType = {
        autDiscount: 0,
        companyAdress: "",
        companyBIK: "",
        companyBankAccount: "",
        companyBankName: "",
        companyINN: "",
        companyKPP: "",
        companyMail: "",
        companyName: "",
        companyPassport: "",
        companyPassword: "",
        companyPhone: "",
        companySWIFT: "",
        companyUNP: "",
        country: "",
        discount: 0,
        regOrderNumber: "",
        whoIsDealer: "",
        status: "",
        type: ""

    }

    state = {
        step: 1,
    }

    userData = (data: AddFormType) => {
        this.data = data
        console.log('Data recieved', this.data)
        this.setState({
            step: 2,
        })
    }

    onUpdate = () => {
        this.setState({
            step: 1
        })
    }

    render() {
        const {step} = this.state
        if (step === 1) {
            return (
                <AdminUpdateForm sendData={this.userData} />
            )
        } else {
            return (
                <AdminAddForm callPopup={this.props.callPopup} formType='update' data={this.data} func={this.onUpdate} />
            )
        }
    }
}