import React from "react";
import {FinalData, FormValues, Item, OrderItems, UserData} from "../../types/types";
import {Link, Redirect, RouteComponentProps, useHistory, useLocation, withRouter} from "react-router-dom";
import {StaticContext} from "react-router";
import axios from "axios";

type LocationState = {
    items: OrderItems,
    userData: UserData,
    formData: FormValues,
    isAuthorised: boolean,
    callPopup: (message: string, type: string) => void
}

const OrderSummary = (prop: RouteComponentProps<{}, StaticContext, LocationState>) => {

    let history = useHistory()
    const location = useLocation<LocationState>()

    if (prop.location.state === undefined){
        return <Redirect to='/'/>
    }

    console.log(location.state)
    const items = location.state.items.items
    const additionalItems = location.state.items.additionalItems
    const rate = location.state.items.rate ? location.state.items.rate : 1
    const userData = location.state.userData
    const formData = location.state.formData
    const callPopup = location.state.callPopup

    let index = 0

    const sendToServer = () => {
        const api = axios.create({
            baseURL: 'https://wholesale.markiz.by/'
        })

        callPopup('Данные обрабатываются сервером. Пожалуйста подождите!', 'message')

        let finalData: FinalData

        if (items) {
            finalData = {
                companyInformation: userData,
                itemsInformation: location.state.items
            }
        } else {
            callPopup('Недостаточно данных для отправки', 'error')
            return
        }

        api.post('/mainScript.php', finalData).then((res) => {
            if (res.data ==='Message sent!') {
                callPopup('Заказ был успешно отправлен!', 'success')
                console.log('We are about to finish')
                history.push({pathname: '/user-panel'})
            } else {
                callPopup(res.data, 'error')
            }
        })


    }

    const sumRecomendedPrice = () => {
        let finalPrice = 0
        items?.forEach((item) => {
            finalPrice += mult(item.recommendedPriceEuro, rate)
        })
        additionalItems?.forEach((item) => {
            finalPrice += mult(item.recommendedPriceEuro * rate, item.quantity)
        })
        return finalPrice.toFixed(2)
    }

    const sumOrder = () => {
        let sum = 0;

        items?.forEach((item) => {
            sum += mult(item.priceEuro, rate)
        })

        additionalItems?.forEach((item) => {
            sum += mult(item.priceEuro * item.quantity, rate)
        })

        return sum.toFixed(2)
    }

    const mult = (x: number, y: number | undefined) => {
        if (!y) {
            y = 1
        }
        return Math.ceil((x*y)*100)/100
    }

    const currency = (country: string) => {
        if (rate === 1){
            return 'Cтоимость единицы (EUR)'
        }

        switch (country) {
            case 'Россия': {
                return 'Cтоимость единицы (RUB)'
            }
            case 'Беларусь': {
                return 'Cтоимость единицы (BYN)'
            }
            case 'Казахстан': {
                if (userData.role === 'Dealer') {
                    return 'Cтоимость единицы (EUR)'
                } else {
                    return 'Cтоимость единицы (KZT)'
                }
            }
        }
    }

    const itemDescription = (item: Item) => {
        switch (item.name) {
            case 'Тент на люверсах.\n':
                return(
                    <td>
                        {item.name}<br/>
                        Размеры: ширина {item.width} мм, выпуск {item.height} мм.<br/>
                        Вид монтажа: {item.montageType}<br/>
                        Артикул ткани: {item.tentManufactor} {item.tentArticle}. {item.tentControlSide ? item.tentControlSide+'.' : ''}<br/>
                    </td>
                    )
            case 'Выставочный стенд':
                return (
                    <td>
                        {item.name}<br/>
                    </td>
                )
            case 'Вертикальные маркизы (экраны) ASN 130 GPZ.\n':
            case 'Вертикальные маркизы (экраны) ASN 110 cabrio.\n':
            case 'Вертикальные маркизы (экраны) ASN 130 GPZ TENZ.\n':
            case 'Локтевые маркизы Domea.\n':
                return (
                    <td>
                        Размеры: ширина {item.width} мм, выпуск {item.height} мм.<br/>
                        Вид монтажа: {item.montageType}<br/>
                        Артикул ткани: {item.tentManufactor} {item.tentArticle}. {item.tentControlSide ? item.tentControlSide+'.' : ''}<br/>
                        В комплекте: {item.electricDriver}. {item.electricDriverSide}.<br/>
                        Цвет профилей: {item.color}.
                    </td>
                )
            case 'Купольные маркизы CLASSIC.\n':
            case 'Купольные маркизы MODERN.\n':
            case 'Локтевые маркизы Optima.\n':
            case 'Локтевые маркизы Helix.\n':
            case 'Локтевые маркизы Optima PLUS.\n':
            case 'Локтевые маркизы VIKTORY.\n':
            case 'Локтевые маркизы Гармония.\n':
            case 'Витринные маркизы ITALIA.\n':
                return (
                    <td>
                        {item.name}<br/>
                        Размеры: ширина {item.width} мм, выпуск {item.height} мм.<br/>
                        Тип волана: {item.volan}.<br/>
                        Тип крепления: {item.montageType}<br/>
                        Артикул ткани: {item.tentManufactor} {item.tentArticle}.<br/>
                        Управление: {item.electricDriver} {item.electricDriverSide}<br/>
                        Цвет профилей: {item.color}.
                    </td>
                )
        }
    }

    return(
        <div>
            <div className='print:hidden absolute right-10 '>
                Курс евро: {rate}
            </div>
            <div className='flex w-full mt-10 justify-center'>
                <table className='table-auto'>
                    <thead>
                        <tr className='bg-gray-50'>
                            <th scope="col">#</th>
                            <th scope="col">Наименование</th>
                            <th scope="col">Кол-во</th>
                            <th scope="col">{currency(userData.country)}</th>
                            <th scope="col">{userData.role === 'Dealer' ? 'Всего, без НДС' : 'Всего'}</th>
                            <th scope="col">{userData.role === 'Dealer' ? 'Рекомендуемая розничная цена без НДС' : 'Рекомендуемая розничная цена всего:'}</th>
                        </tr>
                    </thead>
                    <tbody>
                    {
                        items?.map((item, i) => {
                            index ++
                            return (
                                <tr key={i}>
                                    <th scope='row'>{index}</th>
                                    {
                                        itemDescription(item)
                                    }
                                    <td>1</td>
                                    <td>{(item.priceEuro * rate).toFixed(2)}</td>
                                    <td>{(item.priceEuro * rate).toFixed(2)}</td>
                                    <td>{(item.recommendedPriceEuro * rate).toFixed(2)}</td>
                                </tr>
                            )
                        })
                    }
                    {
                        additionalItems?.map((item, i) => {
                            index++
                            return(
                                <tr key={i}>
                                    <th scope="row" >{index}</th>
                                    <td>{item.description}.</td>
                                    <td>{item.quantity}</td>
                                    <td>{mult(item.priceEuro, rate).toFixed(2)}</td>
                                    <td>{mult(item.priceEuro * rate, item.quantity).toFixed(2)}</td>
                                    <td>{mult(item.recommendedPriceEuro * rate, item.quantity).toFixed(2)}</td>
                                </tr>
                                )
                        })
                    }
                    <tr>
                        <th></th>
                        <td></td>
                        <td></td>
                        <td></td>
                        <td>{sumOrder()}</td>
                        <td>{sumRecomendedPrice()}</td>
                    </tr>
                    </tbody>
                </table>
            </div>
            <div className='flex mt-5 justify-center'>
                <Link className='print:hidden bg-blue-500 hover:bg-blue-600 text-white font-bold py-3 px-4 rounded mr-5' to={{ pathname: '/order-form', state: { savedOrder: formData} }}>Вернутся назад</Link>
                <button className='print:hidden bg-yellow-500 hover:bg-yellow-600 text-white font-bold py-3 px-4 rounded mr-5' onClick={() => {
                    window.print()
                }}>Распечатать таблицу</button>
                {
                    (userData.mail === 'demo@version.rf' || userData.mail === 'demo@version.kz') ?
                        <React.Fragment>
                        </React.Fragment>
                        :
                        <React.Fragment>
                            <button className='print:hidden bg-green-500 hover:bg-green-600 text-white font-bold py-3 px-4 rounded' onClick={() => {
                                sendToServer()
                            }}>Оформить заказ</button>
                        </React.Fragment>
                }
            </div>
        </div>
    )

}

export default withRouter(OrderSummary)