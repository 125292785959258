import React from "react";
import { useForm, SubmitHandler } from "react-hook-form";
import {LoginData} from "../../types/types";
import {Redirect} from "react-router-dom";

type LoginProps = {
    login: (data: LoginData) => void,
    role: string,
    isAuthorised: boolean
}
const Login = ({login, isAuthorised, role}: LoginProps) => {

    //Creating a form handler and execute login function of UserService
    const { register, handleSubmit, formState: { errors } } = useForm<LoginData>();
    console.log(errors)
    const onSubmit: SubmitHandler<LoginData> = data => {
        login(data)
    };

    if(isAuthorised){
        console.log(isAuthorised)
        const url = role === 'admin' ? '/admin-panel' : '/user-panel'
        return <Redirect to={url} />
    }

    return(
        <div className='container flex flex-wrap content-center h-screen mx-auto'>
            <div className='flex flex-wrap justify-center shadow-md border border-blue-400 rounded p-6 mx-auto w-80'>
                <p className='mb-4'>Авторизация</p>
                <form onSubmit={handleSubmit(onSubmit)} className='flex flex-wrap justify-center'>
                    <input className='w-full p-1.5 rounded-sm ring-1 ring-gray-200 focus:ring-blue-300' type="text" placeholder='Email' {...register("email", {required: true, pattern: /^\S+@\S+$/i})}/>
                    <input className='w-full my-3 p-1.5 rounded-sm ring-1 ring-gray-200 focus:ring-blue-300' type="password" placeholder='Пароль' {...register("password", {required: true})}/>
                    <input className='w-1/4 p-2 bg-blue-300 rounded-md hover:text-white hover:bg-blue-400' type="submit" value='Войти'/>
                </form>
            </div>
        </div>
    )
}

export default Login;