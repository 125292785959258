import React, { useState} from "react";
import LeftMenu from "../parts/left-menu/left-menu";
import AdminCompleteForm from "../parts/form-elements/admin-complete-form";
import AdminAddForm from "../parts/form-elements/admin-add-form";
import AdminUpdateContainer from "../parts/form-elements/admin-update-container";

type props = {
    callPopup: (message: string, type: string) => void
}

const AdminPanel = ({callPopup}: props) => {

    const [shownData, setShownData] = useState<string>('')

    const menuRedirection = (data: string) => {
        setShownData(data)
    }

    const dataChoice = (data:string) => {
        switch (data) {
            case 'Добавить клиента': {
                return <AdminAddForm callPopup={callPopup} formType="add"/>
            }
            case 'Редактировать данные': {
                return <AdminUpdateContainer callPopup={callPopup} />
            }
            case 'Обработать заказ': {
                return <AdminCompleteForm />
            }
            default: {
                return true
            }
        }
    }

    const menuList = {
        menuItems: ['Добавить клиента', 'Редактировать данные'],
        menuRedirection: menuRedirection
    }

    return(
        <div className="grid grid-cols-12 my-12 mx-10">
            <div className="mt-10 col-span-2">
                <LeftMenu {...menuList} />
                <div
                    onClick={() => {
                        menuRedirection('Обработать заказ')
                    }}
                    className='flex justify-center mt-10 bg-blue-500 cursor-pointer hover:bg-blue-700 text-white font-bold py-3 px-4 rounded'>
                    Обработать заказ
                </div>
            </div>
            <div className="col-span-10 mx-8">
                <div>
                    {dataChoice(shownData)}
                </div>
            </div>
        </div>
    )
}

export default AdminPanel