import React, { Component } from "react";
import OrderFormElements from "../form-elements/order-form-elements";
import {LoginData} from "../../../types/types";

type LeftMenuProps = {
    menuItems: string[]
    menuRedirection: (data: string) => void,
}

const LeftMenu = ({menuItems, menuRedirection}: LeftMenuProps) => {

    return(
        <div className="w-full">
            {
                menuItems.map((item) => {
                    return(
                        <div onClick={() => menuRedirection(item)} key={item}
                             className="py-3 px-4 flex justify-center rounded cursor-pointer hover:text-gray-500">
                            {item}
                        </div>
                    )
                })
            }
        </div>
    )
}

export default LeftMenu