import React from "react";

import logo from "../../img/company-logo.png";

type HeaderProps = {
    isAuthorised: boolean,
    name: string
}

export default function Header({isAuthorised, name}: HeaderProps) {



    return(
        <div className="grid grid-cols-12 py-5 px-10">
            <div className="print:hidden col-span-2">
                <img src={logo} alt="Company-logo"/>
            </div>
            <div className="print:hidden col-span-10 flex justify-end">
                {isAuthorised ? name : ''}
            </div>
        </div>
    )

}