import React from "react";
import OrderFormElements from "../parts/form-elements/order-form-elements";
import {FormValues, UserData} from "../../types/types";
import CalculatorService from "../../services/calculator-service";
import {Redirect, RouteComponentProps, useHistory, withRouter} from "react-router-dom";
import { StaticContext } from 'react-router';
import {DefaultValues} from "react-hook-form";
import {useLocation} from "react-router-dom";

type LocationState = {
    savedOrder: DefaultValues<FormValues>
}
type OrderFormProps = {
    userData: UserData
    callPopup: (message: string, type: string) => void,
    isAuthorised: boolean
}

const OrderForm: React.FunctionComponent<OrderFormProps & RouteComponentProps<{}, StaticContext, LocationState>> = ({userData, callPopup, isAuthorised}: OrderFormProps, prop: RouteComponentProps<{}, StaticContext, LocationState>) =>  {

    let history = useHistory()
    const location = useLocation<LocationState>()

    if (!isAuthorised){
        return <Redirect to='/'/>
    }

    const onSubmit = (data: FormValues) => {
        const Calculator = new CalculatorService(userData)
        Calculator.dataInitialization(data).then((items) => {
            history.push({pathname: '/order-summary',state:{items: items, userData: userData, formData: data, isAuthorised: isAuthorised, callPopup: callPopup}})
        })
    }

    if (location.state) {
        return <OrderFormElements callPopup={callPopup} mail={userData.mail} savedOrderData={location.state.savedOrder} onSubmit={onSubmit} />
    }

    return <OrderFormElements callPopup={callPopup} mail={userData.mail} onSubmit={onSubmit} />
}

export default withRouter(OrderForm)