import React, {Component} from "react";
import LeftMenu from "../parts/left-menu/left-menu";
import {Accordeon} from "../parts/accordeon/accordeon";
import {Redirect, Link} from "react-router-dom";
import {UserData} from "../../types/types";
import SaveSystemService from "../../services/save-system-service";

type UserPanelProps = {
    isAuthorised: boolean,
    userData: UserData,
    callPopup: (message: string, type: string) => void
}

export default class UserPanel extends Component<UserPanelProps, any> {

    fetchService = new SaveSystemService()

    state = {
        data: []
    }

    constructor(props: UserPanelProps) {
        super(props);

        console.log('props: ', props)

        if(this.props.isAuthorised){
            this.fetchService.getSavedOrders(props.userData.mail).then( (res) => {
                    console.log('SavedOrders from DB: ', res)
                    this.setState({
                        data: res
                    })
                }
            )
        }
    }

    menuRedirection = (data: string) => {
        console.log(data)
    }

    render() {

        const { data } = this.state
        const { userData } = this.props
        const { role } = userData

        if (role === 'admin') {
            return <Redirect to='/' />
        }

        const menuList = {
            menuItems: [],
            menuRedirection: this.menuRedirection
        }

        return(
            <div className="grid grid-cols-12 my-12 mx-10">
                <div className="col-span-2">
                    <LeftMenu {...menuList} />
                    <Link to='/order-form' className='flex justify-center mt-10 bg-blue-500 cursor-pointer hover:bg-blue-700 text-white font-bold py-3 px-4 rounded'>
                        Создать заказ
                    </Link>
                </div>
                <div className="col-span-10 mx-8">
                    <div>
                        <h1>Saved Orders</h1>
                        <Accordeon callPopup={this.props.callPopup} listOfOrders={data}/>
                    </div>
                </div>
            </div>
        )
    }
}