import React, {useEffect, useState} from "react";
import {AccordeonItem} from "./accordeon-item";
import SaveSystemService from "../../../services/save-system-service";

type AccordeonProps = {
    listOfOrders: any,
    callPopup: (message: string, type: string) => void
}

export const Accordeon = ({listOfOrders, callPopup}:AccordeonProps) => {

    const initialState = listOfOrders ? listOfOrders : false
    const [ordersList, setOrdersList] = useState(initialState)

    useEffect(()=>{
        setOrdersList(listOfOrders)
    }, [listOfOrders])

    const deleteOrder = (index: number, id: number) => {
        const saveSystem = new SaveSystemService()

        saveSystem.deleteOrder(id).then((res)=>{
            if(res) {
                callPopup('Заказ успешно удален!', 'success')
                if(ordersList) {
                    setOrdersList(ordersList.filter((order: any) => order !== ordersList[index]))
                }
            } else {
                callPopup('Заказ не был удален!', 'error')
            }
        })
    }

    return (
        <div className="px-4 py-16 mx-auto md:px-24 lg:px-8 lg:py-20">
            <div className="sm:mx-auto">
                <div className="space-y-4">
                    { ordersList ?
                        ordersList.map((order: any, index: number) => {
                            console.log('Accordeon:', order.data)
                            return (
                               <AccordeonItem key={order.id} index={index} deleteOrder={deleteOrder} title={'Заказ номер '+order.id} data={order.data} id={order.id}/>
                            )
                        })
                        : false}
                </div>
            </div>
        </div>
    );
};