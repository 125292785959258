import React, {useEffect, useState} from "react";
import {DefaultValues, useFieldArray, useForm} from "react-hook-form"
import {FormValues} from "../../../types/types";
import SaveSystemService from "../../../services/save-system-service";
import {Link} from "react-router-dom";

type FormElementsProps = {
    onSubmit: (data: FormValues) => void,
    mail: string,
    savedOrderData?: DefaultValues<FormValues>,
    callPopup: (message: string, type: string) => void
}

interface KeyValueArr {
    [key: string]: {
        min: number,
        max: number
    }
}

const OrderFormElements = ({ onSubmit, savedOrderData, mail, callPopup }: FormElementsProps) => {

    const [isSaved, setIsSaved] = useState(false)

    const { register, control, watch, reset, handleSubmit } = useForm<FormValues>({
        mode: 'onChange',
        shouldUnregister: true
    })

    const { fields, append, remove, update } = useFieldArray({
        name: "items",
        control
    })

    const SaveSystem = new SaveSystemService()

    useEffect(() => {
        append({})
    }, [append]);

    useEffect(() => {
        if(savedOrderData){
            reset(savedOrderData)
        }
    }, [reset]);

    const widthBoundaries: KeyValueArr = {
        'CLASSIC': {
            min: 500,
            max: 7000
        },
        'MODERN': {
            min: 500,
            max: 4000
        },
        'Helix': {
            min: 1300,
            max: 6000
        },
        'Optima': {
            min: 1300,
            max: 12000
        },
        'Optima PLUS': {
            min: 2100,
            max: 12000
        },
        'Гармония': {
            min: 2100,
            max: 12000
        },
        'VIKTORY': {
            min: 4600,
            max: 12000
        },
        'Domea': {
            min: 2100,
            max: 6000
        },
        'ITALIA': {
            min: 500,
            max: 5000
        },
        'ASN 130 GPZ': {
            min: 600,
            max: 5000
        },
        'ASN 110 cab': {
            min: 600,
            max: 4000
        },
        'ASN 130 GPZ TENS': {
            min: 600,
            max: 5000
        },
        'Тент на люверсах': {
            min: 0,
            max: 100000
        }
    }

    const heightBoundaries: KeyValueArr = {
        'CLASSIC': {
            min: 500,
            max: 1800
        },
        'MODERN': {
            min: 500,
            max: 1800
        },
        'Helix': {
            min: 200,
            max: 3600
        },
        'Optima': {
            min: 200,
            max: 4100
        },
        'Optima PLUS': {
            min: 200,
            max: 3100
        },
        'Гармония': {
            min: 200,
            max: 4350
        },
        'VIKTORY': {
            min: 200,
            max: 5650
        },
        'Domea': {
            min: 200,
            max: 3100
        },
        'ITALIA': {
            min: 200,
            max: 1600
        },
        'ASN 130 GPZ': {
            min: 600,
            max: 5000
        },
        'ASN 110 cab': {
            min: 600,
            max: 3000
        },
        'ASN 130 GPZ TENS': {
            min: 600,
            max: 5000
        },
        'Тент на люверсах': {
            min: 0,
            max: 100000
        }
    }

    const SaveOrder = () => {
         SaveSystem.saveOrder(mail, watch()).then((res) => {
            if(res) {
                callPopup('Заказ успешно сохранен!', 'success')
                setIsSaved(true)
            } else {
                callPopup('Заказ не был сохранен!', 'error')
            }
        })
    }

    const isColorDisplayed = (index: number) => {
        const model = watch().items[index].model
        switch (model) {
            case 'Тент на люверсах': case 'Выставочный стенд':{
                return false
            }
            default: {
                return true
            }
        }
    }

    const isControlDisplayed = (index: number) => {
        const factory = watch().items[index].factory
        const model = watch().items[index].model

        switch (model) {
            case 'Тент на люверсах': case 'Выставочный стенд':{
                return false
            }
            default: {
                return factory !== '';
            }
        }
    }

    const isControlSideDisplayed = (index: number) => {
        const factory = watch().items[index].factory
        const model = watch().items[index].model

        switch (model) {
            case 'Тент на люверсах': case 'Выставочный стенд':{
                return false
            }
            default: {
                return factory !== '';
            }
        }
    }

    const isFactoryDisplayed = (index: number) => {
        const model = watch().items[index].model
        switch (model) {
            case 'Выставочный стенд':{
                return false
            }
            default: {
                return true
            }
        }
    }

    const isAutomaticFactoryDisplayed = (index: number) => {
        const controlsFactory = watch().items[index].controlsFactory
        const controlAcceptor = watch().items[index].controlAcceptor

        return controlAcceptor === 'Без приемника' || controlsFactory === 'AC инженеринг';
    }

    const isSwbDisplayed = (index: number) => {
        const controlsFactory = watch().items[index].controlsFactory
        const controlAcceptor = watch().items[index].controlAcceptor

        return controlAcceptor === 'Без приемника' || controlsFactory === 'AC инженеринг';
    }

    const isPilotQuantityDisplayed = (index: number) => {
        const model = watch().items[index].model
        const automaticFactory = watch().items[index].automaticFactory
        const controlAceptor = watch().items[index].controlAcceptor

        return model !== 'Выставочный стенд' && model !== 'Тент на люверсах' && (automaticFactory || controlAceptor === 'Cо встроенным приемником');
    }

    const isAutomaticAdditionDisplayed = (index: number) => {
        const controlsFactory = watch().items[index].controlsFactory
        const controlAcceptor = watch().items[index].controlAcceptor

        return controlAcceptor === 'Cо встроенным приемником' || controlsFactory === 'Somfy';
    }

    const isMountTypeDisplayed = (index: number) => {
        const model = watch().items[index].model

        switch (model) {
            case 'ASN 130 GPZ': case 'ASN 110 cab': case 'ASN 130 GPZ TENS': case 'CLASSIC': case 'MODERN': case 'VIKTORY': case 'Helix': case 'Optima': case 'Optima PLUS': case 'Гармония': case 'ITALIA': case 'Domea': {
                return true
            }
            default: {
                return false
            }
        }
    }

    const mountTypeText = (index: number) => {
        const model = watch().items[index].model

        switch (model) {
            case 'ASN 130 GPZ': case 'ASN 110 cab': case 'ASN 130 GPZ TENS':
                return 'Вид монтажа:'
            default:
                return 'Тип крепления:'

        }
    }

    const mountTypeSelect = (index: number) => {
        const model = watch().items[index].model

        switch (model) {
            case 'ASN 130 GPZ': case 'ASN 110 cab': case 'ASN 130 GPZ TENS':
                return (
                    <React.Fragment>
                        <option>Накладной</option>
                        <option>Встроенный</option>
                    </React.Fragment>
                )
            default:
                return (
                    <React.Fragment>
                        <option>Потолочное</option>
                        <option>Стеновое</option>
                    </React.Fragment>
                )

        }
    }

    const isVolanDisplayed = (index: number) => {
        const model = watch().items[index].model

        switch (model) {
            case 'MODERN': case 'CLASSIC': case 'VIKTORY': case 'Helix': case 'Optima': case 'Optima PLUS': case 'Гармония': case 'ITALIA': {
                return true
            }
            default: {
                return false
            }
        }
    }

    const isCapDisplayed = (index: number) => {
        const mountType = watch().items[index].mountType
        const model = watch().items[index].model

        switch (model) {
            case 'VIKTORY': case 'Helix': case 'Optima': case 'Optima PLUS': {
                return mountType !== 'Потолочное';
            }
            default: {
                return false
            }
        }
    }

    const isAdsDisplayed = (index: number) => {
        const model = watch().items[index].model
        const volan = watch().items[index].volan
        switch (model) {
            case 'MODERN': case 'CLASSIC': case 'VIKTORY': case 'Helix': case 'Optima': case 'Optima PLUS': case 'Гармония': case 'ITALIA': {
                return !!((volan !== 'Без волана') && volan)
            }
            default: {
                return false
            }
        }
    }

    const isLEDDisplayed = (index: number) => {
        const model = watch().items[index].model
        const height = watch().items[index].height
        const control = watch().items[index].controlsFactory

        if (control === 'Somfy') {

            switch (model) {
                case 'Optima': {
                    return height >= 3100
                }
                case 'Helix': {
                    return height >= 3100
                }
                case 'VIKTORY':
                case 'Гармония':
                case 'Domea': {
                    return true
                }
                default: {
                    return false
                }
            }
        } else {
            return false
        }
    }

    const isMiniServerDisplayed = (index: number) => {
        const model = watch().items[index].model
        const automaticFactory = watch().items[index].automaticFactory
        const controlsFactory = watch().items[index].controlsFactory
        switch (model) {
            case 'ASN 130 GPZ': case 'ASN 110 cab':
                if (automaticFactory === 'AC инженеринг' || controlsFactory === 'AC инженеринг') {
                    return true
                } else {
                    return false
                }
            default:
                return  false
        }
    }

    const controlsFactorySelect = (index: number) => {
        const model = watch().items[index].model

        switch (model) {
            case 'VIKTORY': case 'Гармония': case 'Domea': case 'ASN 130 GPZ TENS': {
                return (
                    <React.Fragment>
                        <option />
                        <option>Somfy</option>
                    </React.Fragment>
                )
            }
            default: {
                return (
                    <React.Fragment>
                        <option />
                        <option>AC инженеринг</option>
                        <option>Somfy</option>
                    </React.Fragment>
                )
            }

        }
    }

    const factorySelect = (index: number) => {
        const model = watch().items[index].model

        switch (model) {
            case 'CLASSIC': case 'MODERN': case 'VIKTORY': case 'Helix': case 'Optima': case 'Optima PLUS': case 'ITALIA': case 'Domea': case 'Гармония':{
                return (
                    <React.Fragment>
                        <option />
                        <option>Dickson акриловые ткани</option>
                    </React.Fragment>
                )
            }
            case 'ASN 130 GPZ TENS': {
                return (
                    <React.Fragment>
                        <option />
                        <option>COPACO</option>
                        <option>Dickson акриловые ткани</option>
                    </React.Fragment>
                )
            }
            default: {
                return (
                    <React.Fragment>
                        <option />
                        <option>COPACO</option>
                        <option>Dickson акриловые ткани</option>
                        <option>Crystal</option>
                    </React.Fragment>
                )
            }

        }
    }

    const colorSelect = (index: number) => {
        const model = watch().items[index].model

        switch (model) {
            case 'CLASSIC': case 'MODERN': case 'VIKTORY': case 'Helix': case 'Optima': case 'Optima PLUS': case 'ITALIA': case 'Domea': case 'Гармония': case 'ASN 110 cab':{
                return (
                    <React.Fragment>
                        <option />
                        <option value="Ral 9016(белый)">Ral 9016(белый)</option>
                    </React.Fragment>
                )
            }
            default: {
                return (
                    <React.Fragment>
                        <option />
                        <option value="Ral 9016(белый)">Ral 9016(белый)</option>
                        <option value="Ral 9004(черный)">Ral 9004(черный)</option>
                        <option value="Ral 8017(коричневый)">Ral 8017(коричневый)</option>
                        <option value="Ral 1024(бежевый)">Ral 1024(бежевый)</option>
                        <option value="Ral 7024(антрацит)">Ral 7024(антрацит)</option>
                        <option value="Ral 1013(жемчужный)">Ral 1013(жемчужный)</option>
                    </React.Fragment>
                )
            }

        }
    }

    const automaticAdditionSelect = (index: number) => {
        const model = watch().items[index].model

        switch (model) {
            case "Domea": case "Гармония": case 'Optima': case 'ITALIA': case 'Optima PLUS': case 'VIKTORY': case 'Helix':{
                return (
                    <React.Fragment>
                        <option />
                        <option>Автономный датчик ветра</option>
                        <option>Датчик ветра</option>
                    </React.Fragment>
                )
            }
            default: {
                return (
                    <React.Fragment>
                        <option />
                        <option>Датчик ветра</option>
                    </React.Fragment>
                )
            }

        }
    }

    const pultTypeSelect = (index: number) => {
        const length = watch().items.length
        const model = watch().items[index].model

        let select

        switch (model) {
            case 'ASN 130 GPZ': case 'ASN 110 cab': case 'ASN 130 GPZ TENS':
                select = (
                    <React.Fragment>
                        <option />
                        <option>Пятиканальный</option>
                        <option>Пятнадцатиканальный</option>
                    </React.Fragment>
                )
                break
            default:
                select = (
                    <React.Fragment>
                        <option />
                        <option>Одноканальный</option>
                        <option>Пятиканальный</option>
                        <option>Пятнадцатиканальный</option>
                    </React.Fragment>
                )
        }


        return (
            length === 1 ?
                select
                :
                <React.Fragment>
                    <option />
                    <option>Пятиканальный</option>
                    <option>Пятнадцатиканальный</option>
                </React.Fragment>
        )
    }

    const controlAcceptorSelect = (index: number) => {
        const model = watch().items[index].model

        switch (model) {
            case "Domea": case "Гармония": case 'ASN 130 GPZ TENS': {
                return (
                    <React.Fragment>
                        <option />
                        <option>Cо встроенным приемником</option>
                    </React.Fragment>
                )
            }
            case 'Optima': case 'Optima PLUS': case 'Helix': case 'VIKTORY': case 'ITALIA':{
                return (
                    <React.Fragment>
                        <option />
                        <option>Без приемника</option>
                    </React.Fragment>
                )
            }
            case 'ASN 130 GPZ':
                const width = watch().items[index].width
                const height = watch().items[index].height
                const square = width*height

                if (square > 20000000 && square <= 25000000){
                    return (
                        <React.Fragment>
                            <option />
                            <option>Без приемника</option>
                        </React.Fragment>
                    )
                } else {
                    return (
                        <React.Fragment>
                            <option />
                            <option>Без приемника</option>
                            <option>Cо встроенным приемником</option>
                        </React.Fragment>
                    )
                }

            default: {
                return (
                    <React.Fragment>
                        <option />
                        <option>Без приемника</option>
                        <option>Cо встроенным приемником</option>
                    </React.Fragment>
                )
            }

        }
    }

    const controlSelect = (index: number) => {
        const model = watch().items[index].model
        const width = watch().items[index].width
        const height = watch().items[index].height

        switch (model) {
            case "ASN 130 GPZ TENS": {
                return (
                    <React.Fragment>
                        <option />
                        <option>Автоматическое</option>
                    </React.Fragment>
                )
            }
            case "CLASSIC": case "MODERN": {
                return (
                    <React.Fragment>
                        <option />
                        <option>Ручное</option>
                    </React.Fragment>
                )
            }
            case 'VIKTORY':{
                if (width > 5000 || height > 4600){
                    return (
                        <React.Fragment>
                            <option />
                            <option>Автоматическое</option>
                        </React.Fragment>
                    )
                } else {
                    return (
                        <React.Fragment>
                            <option />
                            <option>Ручное</option>
                            <option>Автоматическое</option>
                        </React.Fragment>
                    )
                }
            }
            case 'Гармония':{
                if (width > 7000 || height > 3100){
                    return (
                        <React.Fragment>
                            <option />
                            <option>Автоматическое</option>
                        </React.Fragment>
                    )
                } else {
                    return (
                        <React.Fragment>
                            <option />
                            <option>Ручное</option>
                            <option>Автоматическое</option>
                        </React.Fragment>
                    )
                }
            }
            case 'Optima': case 'Optima PLUS': {
                if (width > 7000){
                    return (
                        <React.Fragment>
                            <option />
                            <option>Автоматическое</option>
                        </React.Fragment>
                    )
                } else {
                    return (
                        <React.Fragment>
                            <option />
                            <option>Ручное</option>
                            <option>Автоматическое</option>
                        </React.Fragment>
                    )
                }
            }
            default: {
                return (
                    <React.Fragment>
                        <option />
                        <option>Ручное</option>
                        <option>Автоматическое</option>
                    </React.Fragment>
                )
            }

        }
    }

    const articleSelect = (index: number) => {

        const factory = watch().items[index].factory

        switch (factory) {
            case 'COPACO': {
                return(
                    <React.Fragment>
                        <option/>
                        <option>008008</option>
                        <option>011011</option>
                        <option>002002</option>
                        <option>003002</option>
                        <option>001001</option>
                        <option>001002</option>
                        <option>008001</option>
                        <option>032040</option>
                        <option>007007</option>
                        <option>010010</option>
                    </React.Fragment>
                    )
            }
            case 'Dickson акриловые ткани': {
                return(
                    <React.Fragment>
                        <option/>
                        <option>3914</option>
                        <option>6316</option>
                        <option>6687</option>
                        <option>6688</option>
                        <option>7133</option>
                        <option>7548</option>
                        <option>8206</option>
                        <option>8601</option>
                        <option>8779</option>
                        <option>8904</option>
                    </React.Fragment>
                )
            }
            case 'Crystal': {
                return(
                    <React.Fragment>
                        <option/>
                        <option>Мягкое стекло</option>
                    </React.Fragment>
                )
            }
        }
    }

    const heightText = (index:number) => {
        const model = watch().items[index].model

        switch (model) {
            case 'CLASSIC': case 'VIKTORY': case 'Helix': case 'Optima': case 'Optima PLUS': case 'ITALIA': case 'Domea': case 'Гармония': {
                return `Выпуск локтя(мм):`
            }
            case 'MODERN': {
                return `Радиус корзины(${heightBoundaries[watch().items[index].model].min} - ${heightBoundaries[watch().items[index].model].max} мм):`
            }
            default: {
                return `Высота(${heightBoundaries[watch().items[index].model].min} - ${heightBoundaries[watch().items[index].model].max} мм):`
            }
        }

    }

    const heightTextInput = (index: number) => {
        const model = watch().items[index].model
        const width = watch().items[index].width

        switch (model) {
            case 'Optima':{
                return (
                    <select id={`items.${index}.height`}
                            {...register(`items.${index}.height` as const,
                                {required: "Обязательное поле",
                                    min: heightBoundaries[watch().items[index].model].min,
                                    max: heightBoundaries[watch().items[index].model].max,
                                    valueAsNumber: true}
                            )} defaultValue={0}>
                        <option/>
                        <option>1600</option>
                        <option>2100</option>
                        <option>2600</option>
                        <option>3100</option>
                        <option>3600</option>
                        <option>4100</option>
                    </select>
                )
            }
            case 'Helix':{
                const width = watch().items[index].width

                if (width <= 5000){
                    return (
                        <select id={`items.${index}.height`}
                                {...register(`items.${index}.height` as const,
                                    {required: "Обязательное поле",
                                        min: heightBoundaries[watch().items[index].model].min,
                                        max: heightBoundaries[watch().items[index].model].max,
                                        valueAsNumber: true}
                                )} defaultValue={0}>
                            <option/>
                            <option>1600</option>
                            <option>2100</option>
                            <option>2600</option>
                            <option>3100</option>
                            <option>3600</option>
                        </select>
                    )
                } else {
                    return (
                        <select id={`items.${index}.height`}
                                {...register(`items.${index}.height` as const,
                                    {required: "Обязательное поле",
                                        min: heightBoundaries[watch().items[index].model].min,
                                        max: heightBoundaries[watch().items[index].model].max,
                                        valueAsNumber: true}
                                )} defaultValue={0}>
                            <option/>
                            <option>3100</option>
                            <option>3600</option>
                        </select>
                    )
                }
            }
            case 'Optima PLUS':{
                return (
                    <select id={`items.${index}.height`}
                            {...register(`items.${index}.height` as const,
                                {required: "Обязательное поле",
                                    min: heightBoundaries[watch().items[index].model].min,
                                    max: heightBoundaries[watch().items[index].model].max,
                                    valueAsNumber: true}
                            )} defaultValue={0}>
                        <option/>
                        <option disabled={width - 1600 < 500}>1600</option>
                        <option disabled={width - 2100 < 500}>2100</option>
                        <option disabled={width - 2600 < 500}>2600</option>
                        <option disabled={width - 3100 < 500}>3100</option>
                    </select>
                )
            }
            case 'VIKTORY':{
                const width = watch().items[index].width
                if (width <= 5000) {
                    return (
                        <select id={`items.${index}.height`}
                                {...register(`items.${index}.height` as const,
                                    {required: "Обязательное поле",
                                        min: heightBoundaries[watch().items[index].model].min,
                                        max: heightBoundaries[watch().items[index].model].max,
                                        valueAsNumber: true}
                                )} defaultValue={0}>
                            <option/>
                            <option disabled={width - 4100 < 500}>4100</option>
                            <option disabled={width - 4600 < 500}>4600</option>
                        </select>
                    )
                } else if (width <= 7000) {
                    return (
                        <select id={`items.${index}.height`}
                                {...register(`items.${index}.height` as const,
                                    {required: "Обязательное поле",
                                        min: heightBoundaries[watch().items[index].model].min,
                                        max: heightBoundaries[watch().items[index].model].max,
                                        valueAsNumber: true}
                                )} defaultValue={0}>
                            <option/>
                            <option disabled={width - 4100 < 500}>4100</option>
                            <option disabled={width - 4600 < 500}>4600</option>
                            <option disabled={width - 5100 < 500}>5100</option>
                            <option disabled={width - 5650 < 500}>5650</option>
                        </select>
                    )
                } else {
                    return (
                        <select id={`items.${index}.height`}
                                {...register(`items.${index}.height` as const,
                                    {required: "Обязательное поле",
                                        min: heightBoundaries[watch().items[index].model].min,
                                        max: heightBoundaries[watch().items[index].model].max,
                                        valueAsNumber: true}
                                )} defaultValue={0}>
                            <option/>
                            <option disabled={width - 4100 < 500}>4100</option>
                            <option disabled={width - 4600 < 500}>4600</option>
                            <option disabled={width - 5100 < 500}>5100</option>
                        </select>
                    )
                }

            }
            case 'ITALIA':{
                return (
                    <select id={`items.${index}.height`}
                            {...register(`items.${index}.height` as const,
                                {required: "Обязательное поле",
                                    min: heightBoundaries[watch().items[index].model].min,
                                    max: heightBoundaries[watch().items[index].model].max,
                                    valueAsNumber: true}
                            )} defaultValue={0}>
                        <option/>
                        <option>800</option>
                        <option>1000</option>
                        <option>1200</option>
                        <option>1400</option>
                        <option>1600</option>
                    </select>
                )
            }
            case 'Domea': {
                const width = watch().items[index].width

                if (width <= 2100) {
                    return (
                        <select id={`items.${index}.height`}
                                {...register(`items.${index}.height` as const,
                                    {required: "Обязательное поле",
                                        min: heightBoundaries[watch().items[index].model].min,
                                        max: heightBoundaries[watch().items[index].model].max,
                                        valueAsNumber: true}
                                )} defaultValue={0}>
                            <option/>
                            <option>1600</option>
                        </select>
                    )
                } else if (width <= 2400) {
                    return (
                        <select id={`items.${index}.height`}
                                {...register(`items.${index}.height` as const,
                                    {required: "Обязательное поле",
                                        min: heightBoundaries[watch().items[index].model].min,
                                        max: heightBoundaries[watch().items[index].model].max,
                                        valueAsNumber: true}
                                )} defaultValue={0}>
                            <option/>
                            <option>1600</option>
                            <option>1850</option>
                        </select>
                    )
                } else if (width <= 2600) {
                    return (
                        <select id={`items.${index}.height`}
                                {...register(`items.${index}.height` as const,
                                    {required: "Обязательное поле",
                                        min: heightBoundaries[watch().items[index].model].min,
                                        max: heightBoundaries[watch().items[index].model].max,
                                        valueAsNumber: true}
                                )} defaultValue={0}>
                            <option/>
                            <option>1600</option>
                            <option>1850</option>
                            <option>2100</option>
                        </select>
                    )
                } else if (width <= 2900) {
                    return (
                        <select id={`items.${index}.height`}
                                {...register(`items.${index}.height` as const,
                                    {required: "Обязательное поле",
                                        min: heightBoundaries[watch().items[index].model].min,
                                        max: heightBoundaries[watch().items[index].model].max,
                                        valueAsNumber: true}
                                )} defaultValue={0}>
                            <option/>
                            <option>1600</option>
                            <option>1850</option>
                            <option>2100</option>
                            <option>2350</option>
                        </select>
                    )
                } else if (width <= 3100) {
                    return (
                        <select id={`items.${index}.height`}
                                {...register(`items.${index}.height` as const,
                                    {required: "Обязательное поле",
                                        min: heightBoundaries[watch().items[index].model].min,
                                        max: heightBoundaries[watch().items[index].model].max,
                                        valueAsNumber: true}
                                )} defaultValue={0}>
                            <option/>
                            <option>1600</option>
                            <option>1850</option>
                            <option>2100</option>
                            <option>2350</option>
                            <option>2600</option>
                        </select>
                    )
                } else if (width <= 3400) {
                    return (
                        <select id={`items.${index}.height`}
                                {...register(`items.${index}.height` as const,
                                    {required: "Обязательное поле",
                                        min: heightBoundaries[watch().items[index].model].min,
                                        max: heightBoundaries[watch().items[index].model].max,
                                        valueAsNumber: true}
                                )} defaultValue={0}>
                            <option/>
                            <option>1600</option>
                            <option>1850</option>
                            <option>2100</option>
                            <option>2350</option>
                            <option>2600</option>
                            <option>2850</option>
                        </select>
                    )
                } else {
                    return (
                        <select id={`items.${index}.height`}
                                {...register(`items.${index}.height` as const,
                                    {required: "Обязательное поле",
                                        min: heightBoundaries[watch().items[index].model].min,
                                        max: heightBoundaries[watch().items[index].model].max,
                                        valueAsNumber: true}
                                )} defaultValue={0}>
                            <option/>
                            <option>1600</option>
                            <option>1850</option>
                            <option>2100</option>
                            <option>2350</option>
                            <option>2600</option>
                            <option>2850</option>
                            <option>3100</option>
                        </select>
                    )
                }
            }
            case 'Гармония': {
                const width = watch().items[index].width

                if (width <= 3000) {
                    return (
                        <select id={`items.${index}.height`}
                                {...register(`items.${index}.height` as const,
                                    {required: "Обязательное поле",
                                        min: heightBoundaries[watch().items[index].model].min,
                                        max: heightBoundaries[watch().items[index].model].max,
                                        valueAsNumber: true}
                                )} defaultValue={0}>
                            <option/>
                            <option>1600</option>
                            <option>1850</option>
                            <option>2100</option>
                            <option>2350</option>
                        </select>
                    )
                } else if (width <= 3600) {
                    return (
                        <select id={`items.${index}.height`}
                                {...register(`items.${index}.height` as const,
                                    {required: "Обязательное поле",
                                        min: heightBoundaries[watch().items[index].model].min,
                                        max: heightBoundaries[watch().items[index].model].max,
                                        valueAsNumber: true}
                                )} defaultValue={0}>
                            <option/>
                            <option>1600</option>
                            <option>1850</option>
                            <option>2100</option>
                            <option>2350</option>
                            <option>2600</option>
                        </select>
                    )
                } else if (width <= 4000) {
                    return (
                        <select id={`items.${index}.height`}
                                {...register(`items.${index}.height` as const,
                                    {required: "Обязательное поле",
                                        min: heightBoundaries[watch().items[index].model].min,
                                        max: heightBoundaries[watch().items[index].model].max,
                                        valueAsNumber: true}
                                )} defaultValue={0}>
                            <option/>
                            <option>1600</option>
                            <option>1850</option>
                            <option>2100</option>
                            <option>2350</option>
                            <option>2600</option>
                            <option>3100</option>
                        </select>
                    )
                } else if (width <= 4800) {
                    return (
                        <select id={`items.${index}.height`}
                                {...register(`items.${index}.height` as const,
                                    {required: "Обязательное поле",
                                        min: heightBoundaries[watch().items[index].model].min,
                                        max: heightBoundaries[watch().items[index].model].max,
                                        valueAsNumber: true}
                                )} defaultValue={0}>
                            <option/>
                            <option>1600</option>
                            <option>1850</option>
                            <option>2100</option>
                            <option>2350</option>
                            <option>2600</option>
                            <option>3100</option>
                            <option>3600</option>
                        </select>
                    )
                } else if (width <= 5000) {
                    return (
                        <select id={`items.${index}.height`}
                                {...register(`items.${index}.height` as const,
                                    {required: "Обязательное поле",
                                        min: heightBoundaries[watch().items[index].model].min,
                                        max: heightBoundaries[watch().items[index].model].max,
                                        valueAsNumber: true}
                                )} defaultValue={0}>
                            <option/>
                            <option>1600</option>
                            <option>1850</option>
                            <option>2100</option>
                            <option>2350</option>
                            <option>2600</option>
                            <option>3100</option>
                            <option>4100</option>
                        </select>
                    )
                } else if (width <= 6000) {
                    return (
                        <select id={`items.${index}.height`}
                                {...register(`items.${index}.height` as const,
                                    {required: "Обязательное поле",
                                        min: heightBoundaries[watch().items[index].model].min,
                                        max: heightBoundaries[watch().items[index].model].max,
                                        valueAsNumber: true}
                                )} defaultValue={0}>
                            <option/>
                            <option>1600</option>
                            <option>1850</option>
                            <option>2100</option>
                            <option>2350</option>
                            <option>2600</option>
                            <option>3100</option>
                            <option>3600</option>
                            <option>4100</option>
                            <option>4350</option>
                        </select>
                    )
                } else if (width <= 7000) {
                    return (
                        <select id={`items.${index}.height`}
                                {...register(`items.${index}.height` as const,
                                    {required: "Обязательное поле",
                                        min: heightBoundaries[watch().items[index].model].min,
                                        max: heightBoundaries[watch().items[index].model].max,
                                        valueAsNumber: true}
                                )} defaultValue={0}>
                            <option/>
                            <option>1600</option>
                            <option>1850</option>
                            <option>2100</option>
                            <option>2350</option>
                            <option>2600</option>
                            <option>3100</option>
                            <option>3600</option>
                            <option>4100</option>
                        </select>
                    )
                } else {
                    return (
                        <select id={`items.${index}.height`}
                                {...register(`items.${index}.height` as const,
                                    {required: "Обязательное поле",
                                        min: heightBoundaries[watch().items[index].model].min,
                                        max: heightBoundaries[watch().items[index].model].max,
                                        valueAsNumber: true}
                                )} defaultValue={0}>
                            <option/>
                            <option>1600</option>
                            <option>1850</option>
                            <option>2100</option>
                            <option>2350</option>
                            <option>2600</option>
                            <option>3100</option>
                            <option>3600</option>
                            <option>4100</option>
                            <option>4350</option>
                        </select>
                    )
                }
            }
            default:
                return (
                    <input id={`items.${index}.height`} type="text"
                           {...register(`items.${index}.height` as const,
                               {required: "Обязательное поле",
                                   min: heightBoundaries[watch().items[index].model].min,
                                   max: heightBoundaries[watch().items[index].model].max,
                                   valueAsNumber: true}
                           )} defaultValue={0} />
                )
        }

    }

    return (
       <form className="mt-10 space-y-5" onSubmit={handleSubmit(onSubmit)}>
           {fields.map((item, index) => {
               return(
                   <div className="border-blue-200 border mx-4 px-2 py-3 grid grid-cols-6 rounded-md" key={item.id}>
                       <div className="input-divs">
                           <label htmlFor={`items.${index}.model`}>Модель:</label>
                           <select id={`items.${index}.model`} {...register(`items.${index}.model` as const, {required: "Обязательное поле"})} defaultValue={item.model} onChange={
                               (e) => {
                                   update(index, {
                                       model: e.target.value,
                                       control: ''
                                   })
                               }
                           }>
                               <option />
                               <option>CLASSIC</option>
                               <option>MODERN</option>
                               <option>Helix</option>
                               <option>Optima</option>
                               <option>Optima PLUS</option>
                               <option>Гармония</option>
                               <option>VIKTORY</option>
                               <option>Domea</option>
                               <option>ITALIA</option>
                               <option>ASN 130 GPZ</option>
                               <option>ASN 110 cab</option>
                               <option>ASN 130 GPZ TENS</option>
                               <option>Тент на люверсах</option>
                               <option>Выставочный стенд</option>
                           </select>
                       </div>
                       {/*If not a Выставочный стенд, then show*/}
                       {(watch().items[index].model !== 'Выставочный стенд' && watch().items[index].model) && (
                           <React.Fragment>
                               <div className="input-divs">
                                   <label htmlFor={`items.${index}.width`}>Ширина({widthBoundaries[watch().items[index].model].min} - {widthBoundaries[watch().items[index].model].max} мм):</label>
                                   <input id={`items.${index}.width`} type="text"
                                          {...register(`items.${index}.width` as const,
                                              {required: "Обязательное поле",
                                                  min: widthBoundaries[watch().items[index].model].min,
                                                  max: widthBoundaries[watch().items[index].model].max,
                                                  valueAsNumber: true}
                                          )} defaultValue={item.width || 0} />
                               </div>
                               <div className="input-divs">
                                   <label htmlFor={`items.${index}.height`}>{heightText(index)}</label>
                                   {heightTextInput(index)}
                               </div>
                           </React.Fragment>
                       )}

                       {
                           isVolanDisplayed(index) && (
                               <div className="input-divs">
                                   <label htmlFor={`items.${index}.volan`}>Тип волана:</label>
                                   <select id={`items.${index}.volan`} {...register(`items.${index}.volan` as const, {required: "Обязательное поле"})} defaultValue={item.volan}>
                                       <option>Без волана</option>
                                       <option>Type A</option>
                                       <option>Type B</option>
                                       <option>Type C</option>
                                       <option>Type D</option>
                                       <option>Type E</option>
                                       <option>Type F</option>
                                       <option>Type G</option>
                                   </select>
                               </div>
                           )
                       }

                       {
                           isMountTypeDisplayed(index) && (
                               <div className="input-divs">
                                   <label htmlFor={`items.${index}.mountType`}>{mountTypeText(index)}</label>
                                   <select id={`items.${index}.mountType`} {...register(`items.${index}.mountType` as const, {required: "Обязательное поле"})} defaultValue={item.mountType}>
                                       <option />
                                       {
                                           mountTypeSelect(index)
                                       }
                                   </select>
                               </div>
                           )
                       }

                       {
                           isCapDisplayed(index) && (
                               <div className="input-divs">
                                   <label htmlFor={`items.${index}.cap`}>Верхняя укрывающая крышка:</label>
                                   <select id={`items.${index}.cap`} {...register(`items.${index}.cap` as const)} defaultValue={item.cap}>
                                       <option>-</option>
                                       <option>Добавить</option>
                                   </select>
                               </div>
                           )
                       }

                       {
                           isAdsDisplayed(index) && (
                               <div className="input-divs">
                                   <label htmlFor={`items.${index}.ads`}>Размещение рекламы:</label>
                                   <select id={`items.${index}.ads`} {...register(`items.${index}.ads` as const)} defaultValue={item.ads}>
                                       <option>-</option>
                                       <option>Добавить</option>
                                   </select>
                               </div>
                           )
                       }

                       {
                           isColorDisplayed(index) && (
                               <div className="input-divs">
                                   <label htmlFor={`items.${index}.color`}>Цвет профилей:</label>
                                   <input type="search" id={`items.${index}.color`} list={`items.${index}.colorOptions`} {...register(`items.${index}.color` as const, {required: "Обязательное поле"})} defaultValue={item.color}/>
                                   <datalist id={`items.${index}.colorOptions`}>
                                       {
                                           colorSelect(index)
                                       }
                                   </datalist>
                               </div>
                           )
                       }

                       {
                           isFactoryDisplayed(index) && (
                               <React.Fragment>
                                   <div className="input-divs">
                                       <label htmlFor={`items.${index}.factory`}>Производитель тента:</label>
                                       <select id={`items.${index}.factory`} {...register(`items.${index}.factory` as const, {required: "Обязательное поле"})} defaultValue={item.factory}>
                                           {
                                               factorySelect(index)
                                           }
                                       </select>
                                   </div>
                                   <div className="input-divs">
                                       <label htmlFor={`items.${index}.article`}>Артикул тента:</label>
                                       <select id={`items.${index}.article`} {...register(`items.${index}.article` as const, {required: "Обязательное поле"})} defaultValue={item.article}>
                                           {articleSelect(index)}
                                       </select>
                                   </div>
                               </React.Fragment>
                           )
                       }

                       {
                           watch().items[index].factory === 'COPACO' && (
                               <div className="input-divs">
                                   <label htmlFor={`items.${index}.article2`}>Сторона ткани в изделии:</label>
                                   <select id={`items.${index}.article2`} {...register(`items.${index}.article2` as const, {required: "Обязательное поле"})} defaultValue={item.article2}>
                                       {
                                           watch().items[index].model === 'Тент на люверсах' ?
                                               <React.Fragment>
                                                   <option />
                                                   <option>Back со стороны люверса</option>
                                                   <option>Front со стороны люверса</option>
                                               </React.Fragment>
                                                :
                                               <React.Fragment>
                                                   <option />
                                                   <option>Back со стороны короба</option>
                                                   <option>Front со стороны короба</option>
                                               </React.Fragment>
                                       }
                                   </select>
                               </div>
                           )
                       }

                       {
                           isControlDisplayed(index) && (
                               <div className="input-divs">
                                   <label htmlFor={`items.${index}.control`}>Управление:</label>
                                   <select
                                       id={`items.${index}.control`} {...register(`items.${index}.control` as const, {required: "Обязательное поле"})}
                                       defaultValue={item.control}>
                                       {
                                           controlSelect(index)
                                       }
                                   </select>
                               </div>
                           )
                       }

                       {
                           isControlSideDisplayed(index) && (
                               <div className="input-divs">
                                   <label htmlFor={`items.${index}.controlSide`}>Сторона управления:</label>
                                   <select id={`items.${index}.controlSide`} {...register(`items.${index}.controlSide` as const, {required: "Обязательное поле"})} defaultValue={item.controlSide}>
                                       <option />
                                       <option>Справа</option>
                                       <option>Слева</option>
                                   </select>
                               </div>
                           )
                       }

                       {
                           watch().items[index].control === 'Автоматическое' && (
                               <div className="input-divs">
                                   <label htmlFor={`items.${index}.controlsFactory`}>Производитель привода:</label>
                                   <select id={`items.${index}.controlsFactory`} {...register(`items.${index}.controlsFactory` as const, {required: "Обязательное поле"})} defaultValue={item.controlsFactory}>
                                       {
                                           controlsFactorySelect(index)
                                       }
                                   </select>
                               </div>
                           )
                       }

                       {
                           watch().items[index].controlsFactory === 'Somfy' && (
                               <div className="input-divs">
                                   <label htmlFor={`items.${index}.controlAcceptor`}>Вид привода:</label>
                                   <select id={`items.${index}.controlAcceptor`} {...register(`items.${index}.controlAcceptor` as const, {required: "Обязательное поле"})} defaultValue={item.controlAcceptor}>
                                       {
                                           controlAcceptorSelect(index)
                                       }
                                   </select>
                               </div>
                           )
                       }

                       {
                           isMiniServerDisplayed(index) && (
                               <div className="input-divs">
                                   <label htmlFor={`items.${index}.server`}>Мини сервер:</label>
                                   <select id={`items.${index}.server`} {...register(`items.${index}.server` as const, {required: "Обязательное поле"})} defaultValue={item.server}>
                                       <option>-</option>
                                       <option>Добавить</option>
                                   </select>
                               </div>
                           )
                       }

                       {
                           isLEDDisplayed(index) && (
                               <div className="input-divs">
                                   <label htmlFor={`items.${index}.led`}>LED:</label>
                                   <select id={`items.${index}.led`} {...register(`items.${index}.led` as const)} defaultValue={item.led}>
                                       <option>-</option>
                                       <option>Добавить</option>
                                   </select>
                               </div>
                           )
                       }

                       {
                           isAutomaticFactoryDisplayed(index) && (
                               <div className="input-divs">
                                   <label htmlFor={`items.${index}.automaticFactory`}>Производитель автоматики:</label>
                                   <select id={`items.${index}.automaticFactory`} {...register(`items.${index}.automaticFactory` as const, {required: "Обязательное поле"})} defaultValue={item.automaticFactory}>
                                       {
                                           watch().items[index].controlsFactory === 'Somfy'?
                                               <React.Fragment>
                                                   <option>Без автоматики</option>
                                                   <option>Somfy</option>
                                               </React.Fragment>
                                               :
                                               <React.Fragment>
                                                   <option>Без автоматики</option>
                                                   <option>AC инженеринг</option>
                                               </React.Fragment>

                                       }
                                   </select>
                               </div>
                           )
                       }

                       {
                           isSwbDisplayed(index) && (
                               <div className="input-divs">
                                   <label htmlFor={`items.${index}.swb`}>Выключатель для накладного монтажа:</label>
                                   <select id={`items.${index}.swb`} {...register(`items.${index}.swb` as const)} defaultValue={item.swb}>
                                       <option>-</option>
                                       <option>SWB</option>
                                   </select>
                               </div>
                           )
                       }

                       {
                           isPilotQuantityDisplayed(index) && (
                               <div className="input-divs">
                                   <label htmlFor={`items.${index}.pilotQuantity`}>Кол-во пультов:</label>
                                   <input id={`items.${index}.pilotQuantity`} type="text" {...register(`items.${index}.pilotQuantity` as const, {valueAsNumber: true})} defaultValue={item.pilotQuantity || ''} />
                               </div>
                           )
                       }

                       {
                           (watch().items[index].automaticFactory || watch().items[index].controlAcceptor === 'Cо встроенным приемником') && (
                               <div className="input-divs">
                                   <label htmlFor={`items.${index}.pilotType`}>Вид пультов:</label>
                                   <select id={`items.${index}.pilotType`} {...register(`items.${index}.pilotType` as const)} defaultValue={item.pilotType}>
                                       {
                                           pultTypeSelect(index)
                                       }
                                   </select>
                               </div>
                           )
                       }

                       {
                           isAutomaticAdditionDisplayed(index) && (
                               <div className="input-divs">
                                   <label htmlFor={`items.${index}.automaticAddition`}>Дополнительная автоматика:</label>
                                   <select id={`items.${index}.automaticAddition`} {...register(`items.${index}.automaticAddition` as const)} defaultValue={item.automaticAddition}>
                                       {
                                           automaticAdditionSelect(index)
                                       }
                                   </select>
                               </div>
                           )
                       }


                       {
                           watch().items[index].model === 'Тент на люверсах' && (
                               <React.Fragment>
                                   <div className="input-divs">
                                       <label htmlFor={`items.${index}.zip`}>Длина молни (метры):</label>
                                       <input id={`items.${index}.zip`} type="text" {...register(`items.${index}.zip` as const, {required: "Обязательное поле", valueAsNumber: true})} defaultValue={item.zip || ''} />
                                   </div>
                                   <div className="input-divs">
                                       <label htmlFor={`items.${index}.belts`}>Кол-во ремешк.:</label>
                                       <input id={`items.${index}.belts`} type="text" {...register(`items.${index}.belts` as const, {valueAsNumber: true})} defaultValue={item.belts || ''} />
                                   </div>
                               </React.Fragment>
                           )
                       }
                       <div className="input-divs">
                           <label htmlFor={`items.${index}.notes`}>Примечание:</label>
                           <input id={`items.${index}.notes`} {...register(`items.${index}.notes` as const )} defaultValue={item.notes}/>
                       </div>
                       <div className="flex">
                           <button className="self-center bg-red-500 hover:bg-red-600 text-white font-bold py-2.5 px-4 ml-3 rounded focus:outline-none focus:shadow-outline" type="button" onClick={() => {remove(index)}}>X</button>
                           <button className="self-center bg-green-500 hover:bg-green-600 text-white font-bold py-2.5 ml-3 px-4 rounded focus:outline-none focus:shadow-outline" type="button" onClick={() => {append(watch(`items.${index}`))}}>Создать копию</button>
                       </div>
                   </div>
               )
           })}
           <div className="flex justify-center space-x-4">
               {
                   (mail === 'demo@version.kz' || mail === 'demo@version.rf') ?
                       <React.Fragment>
                           <Link className='print:hidden bg-blue-500 hover:bg-blue-600 text-white font-bold py-3 px-4 rounded mr-5' to={{ pathname: '/user-panel'}}>Вернутся назад</Link>
                           <button className="bg-green-500 hover:bg-green-600 text-white font-bold py-3 px-4 rounded focus:outline-none focus:shadow-outline" type="button" onClick={() => append({})}>Добавить товар</button>
                           <button className="bg-blue-500 hover:bg-blue-600 text-white font-bold py-3 px-4 rounded focus:outline-none focus:shadow-outline" type="submit">Далее</button>
                       </React.Fragment>
                       :
                       <React.Fragment>
                           <Link className='print:hidden bg-blue-500 hover:bg-blue-600 text-white font-bold py-3 px-4 rounded mr-5' to={{ pathname: '/user-panel'}}>Вернутся назад</Link>
                           <button className="disabled:bg-yellow-200 bg-yellow-500 hover:bg-yellow-600 text-white font-bold py-3 px-4 rounded focus:outline-none focus:shadow-outline" type="button" disabled={isSaved} onClick={SaveOrder}>Сохранить заказ</button>
                           <button className="bg-green-500 hover:bg-green-600 text-white font-bold py-3 px-4 rounded focus:outline-none focus:shadow-outline" type="button" onClick={() => append({})}>Добавить товар</button>
                           <button className="bg-blue-500 hover:bg-blue-600 text-white font-bold py-3 px-4 rounded focus:outline-none focus:shadow-outline" type="submit">Далее</button>
                       </React.Fragment>

               }
           </div>
       </form>
   )
}

export default OrderFormElements