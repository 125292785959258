import React, { useRef, useState} from "react";
import {HashRouter as Router, Redirect, Route} from 'react-router-dom';

import AdminPanel from "./components/admin-panel/admin-panel";
import UserPanel from "./components/user-panel/user-panel";
import OrderForm from "./components/order-form/order-form";
import Login from "./components/login/login";
import OrderSummary from "./components/order-summary/order-summary";
import Header from "./components/header/header";
import UserService from "./services/user-service";
import {LoginData, UserData} from "./types/types";
import {Alerts} from "./components/parts/notifications/alerts";

const App = () => {

    //User data information object creation
    const [isAuthorised, setIsAuthorised] = useState(false)
    const [userData, setUserData] = useState<UserData>({
        name: '',
        type: '',
        country: '',
        address: '',
        mail: '',
        unp: '',
        inn: '',
        kpp: '',
        bankName: '',
        bankAccount: '',
        swift: '',
        bik: '',
        phone: '',
        passport: '',
        orderNumber: '',
        role: '',
        dealerDiscounts: {
            discount: 0,
            autDiscount: 0
        },
        discount: 0,
        autDiscount: 0
    })
    const [message, setMessage ] = useState('')
    const [type, setType] = useState('')



    //change popup data
    const callPopup = (message: string, type: string) => {
        console.log('message, type', message, type)
        setMessage(message)
        setType(type)
        if (snackbarRef.current){
            snackbarRef.current.show()
        }
    }

    //userService.login() execution if succeed change isAuthorised state on true
    const login = async (data: LoginData) => {
        await UserService(data).then((response) => {
            if (response !== 'Error'){
                if(response.mail !== '') {
                    setUserData(response)
                    setIsAuthorised(true)
                    callPopup('Вы успешно вошли в систему!', 'success')
                } else {
                    callPopup('Ошибка в работе сервера! Обратитесь к администратору.', 'error')
                }
            }
            else {
                callPopup('Ошибка входа в систему', 'error')
            }
        })
    }
    const snackbarRef = useRef<{show: ()=> void}>(null)

    return (
        <div>
            <Header isAuthorised={isAuthorised} name={userData.name}/>
            <Router>
                {
                    !isAuthorised && (<Redirect to='/' />)
                }
                <Route path="/" render={() => (
                    <Login login={login} isAuthorised={isAuthorised} role={userData.role}/>
                )} exact/>
                <Route path="/admin-panel" render={() => (
                    <AdminPanel callPopup={callPopup} />
                )} exact/>
                <Route path="/user-panel" render={() => (
                    <UserPanel callPopup={callPopup} isAuthorised={isAuthorised} userData={userData} />
                )} exact/>
                <Route path="/order-form" render={props => (
                    <OrderForm {...props} userData={userData} callPopup={callPopup} isAuthorised={isAuthorised}/>
                )} exact/>
                <Route path="/order-summary" component={OrderSummary} exact/>
            </Router>
            <Alerts
                ref={snackbarRef}
                message={message}
                type={type} />
        </div>
    );
}

export default App