import React, {useState} from "react";
import {Link} from "react-router-dom";
import {DefaultValues} from "react-hook-form";
import {FormValues} from "../../../types/types";

type AccordeonItem = {
    title: string,
    data: DefaultValues<FormValues>,
    id: number,
    deleteOrder: (index: number, id: number) => void,
    index: number
}

export const AccordeonItem = ({ title, data, id, deleteOrder, index }: AccordeonItem) => {

    const [isOpen, setIsOpen] = useState(false);

    console.log('Accordeon Item', data)
    return (
        <div className="border rounded shadow-sm">
            <button
                type="button"
                aria-label="Open item"
                title="Open item"
                className="flex items-center justify-between w-full p-4 focus:outline-none"
                onClick={() => setIsOpen(!isOpen)}
            >
                <p className="text-lg font-medium">{title}</p>
                <div className="flex items-center justify-center w-8 h-8 border rounded-full">
                    <svg
                        viewBox="0 0 24 24"
                        className={`w-3 text-gray-600 transition-transform duration-200 ${
                            isOpen ? 'transform rotate-180' : ''
                        }`}
                    >
                        <polyline
                            fill="none"
                            stroke="currentColor"
                            strokeWidth="2"
                            strokeLinecap="round"
                            strokeMiterlimit="10"
                            points="2,7 12,17 22,7"
                            strokeLinejoin="round"
                        />
                    </svg>
                </div>
            </button>
            {isOpen && (
                <div className="p-4 pt-0">
                    <p className="text-gray-700">
                        {
                            data.items?.map((item)=>{
                                return item.notes
                            })
                        }
                    </p>
                    <Link to={{ pathname: '/order-form', state: { savedOrder: data} }} className='inline-block justify-center mt-10 bg-blue-500 cursor-pointer mr-4 hover:bg-blue-700 text-white font-bold py-3 px-4 rounded'>
                        Заказать повторно
                    </Link>
                    <button className={'inline-block justify-center mt-10 bg-red-500 cursor-pointer hover:bg-red-700 text-white font-bold py-3 px-4 rounded'} onClick={() => {
                        deleteOrder(index, id)
                    }}>Удалить заказ</button>
                </div>
            )}
        </div>
    );
};