import React from "react";
import axios from "axios";
import {useForm} from "react-hook-form";
import {AddFormType} from "../../../types/types";

type UpdateFormValues = {
    email: string
}

type UpdateFormProps = {
    sendData: (data: AddFormType) => void
}

const AdminUpdateForm = ({sendData}: UpdateFormProps) => {

    const { register, handleSubmit } = useForm<UpdateFormValues>({
        shouldUnregister: true
    })

    const api = axios.create({
        baseURL: 'https://wholesale.markiz.by/'
    })

    let userData: AddFormType = {
        type: '',
        country: '',
        status: '',
        regOrderNumber: '',
        companyName: '',
        companyMail: '',
        companyAdress: '',
        companyUNP: '',
        companySWIFT: '',
        companyINN: '',
        companyBIK: '',
        companyKPP: '',
        companyBankName: '',
        companyBankAccount: '',
        companyPhone: '',
        companyPassport: '',
        companyPassword: '',
        whoIsDealer: '',
        discount: 0,
        autDiscount: 0
    }

    const onSubmit = (request: UpdateFormValues) => {
        api.post('/getToUpdate.php', request).then((res) => {
            console.log(res)
            if (res.data.error !== 'Не правильный e-mail') {
                const data = res.data
                console.log('Data we get', res.data)
                userData = {
                    companyName: data.companyName,
                    regOrderNumber: data.orderNumber,
                    type: data.companyType,
                    country: data.companyContry,
                    companyAdress: data.companyAdress,
                    companyMail: data.companyMail,
                    companyUNP: data.companyUNP,
                    companyINN: data.companyINN,
                    companyKPP: data.companyKPP,
                    companyBankName: data.companyBankName,
                    companyBankAccount: data.companyBankAccount,
                    companySWIFT: data.companySWIFT,
                    companyBIK: data.companyBIK,
                    companyPhone: data.companyPhone,
                    companyPassport: data.companyPassport,
                    companyPassword: data.companyPassword,
                    discount: data.discount,
                    whoIsDealer: data.whoIsDealer,
                    autDiscount: data.autDiscount,
                    status: data.type
                }
                console.log('Data we use', userData)
                sendData(userData)
            }
        })
    }

    return(
        <form onSubmit={handleSubmit(onSubmit)}>
            <div className="input-divs">
                <label htmlFor="email">Впишите e-mail клиента:</label>
                <input type="text" id="email" {...register("email", {required: "Обязательное поле"})} />
            </div>
            <div className="flex justify-center space-x-4">
                <button className="bg-blue-500 hover:bg-blue-600 text-white font-bold py-3 px-4 rounded focus:outline-none focus:shadow-outline"
                        type="submit">Далее</button>
            </div>
        </form>
    )
}

export default AdminUpdateForm