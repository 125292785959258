import React from "react";
import axios from "axios";

const AdminCompleteForm = () => {

    const api = axios.create({
        baseURL: 'https://wholesale.markiz.by/'
    })

    const generateFile = (event: React.ChangeEvent<HTMLInputElement>) => {
        if (event.target.files) {
            let fileList: FileList = event.target.files;
            if (fileList.length > 0){
                let file: File = fileList[0]
                let formData: FormData = new FormData()
                formData.append('uploadFile', file, file.name)


                api.post('/sideAdmin.php',formData).then(
                    (response) => {
                        if (response.data !== 'Готово'){
                            console.log('Fail')
                        } else {
                            console.log('Success')
                        }
                    }
                )
            }
        }
    }

    return(
        <input id='file' onChange={(event) => {generateFile(event)}} type="file" accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet" />
    )
}

export default AdminCompleteForm