import {AdditionalItem, DealerDiscounts, FormValues, Item, ItemData, UserData} from "../types/types";
import axios from "axios";
import React from "react";

export default class CalculatorService {

    NDS: {
        sub: number,
        dealer: number
    }
    userData: UserData

    colored: boolean

    constructor(userData: UserData) {
        this.userData = userData
        this.NDS = this.setNDS(userData.country, userData.type, userData.role)
        this.colored = false
    }

     GetRecomendedPriceMultipler (model: string) {
        switch (model) {
            case 'CLASSIC': case 'Тент на люверсах': {
                return {
                    model: 1.25,
                    automation: 1
                }
            }
            case 'MODERN': {
                return {
                    model: 1.2,
                    automation: 1
                }
            }
            case 'Optima': {
                return {
                    model: 1.2,
                    automation: 1.15
                }
            }
            case 'Optima PLUS': case 'VIKTORY': case 'Helix': case 'Гармония': case 'Domea': {
                return {
                    model: 1.25,
                    automation: 1.15
                }
            }
            case 'ITALIA': {
                return {
                    model: 1.27,
                    automation: 1.15
                }
            }
            case 'ASN 130 GPZ': case 'ASN 110 cab': case 'ASN 130 GPZ TENS': {
                return {
                    model: 1.48,
                    automation: 1.15
                }
            }
            default: {
                return {
                    model: 1,
                    automation: 1
                }
            }
        }
    }


    countPriceOfItem(item: ItemData, dealerDiscounts?: DealerDiscounts, isRecomended: boolean = false): number {
        let priceTable: number[][]
        let standartPrice: number
        let colorPrice: number
        let factoryPrice: number
        let electricDriverPrice: number
        let finalPrice: number
        let heightIndex: number
        let widthIndex: number
        let discount: number
        let autDiscount: number

        if (isRecomended) {
            discount = 0
            autDiscount = 0
        } else if (dealerDiscounts) {
            discount = dealerDiscounts.discount
            autDiscount = dealerDiscounts.autDiscount
        } else {
            discount = this.userData.discount
            autDiscount = this.userData.autDiscount
        }

        const RecPriceCof = isRecomended ? this.GetRecomendedPriceMultipler(item.model) : {model: 1, automation: 1}

        switch (item.model) {
            case 'Выставочный стенд': {
                let finalPrice = (101 * (1 - autDiscount/100))*RecPriceCof.automation
                if(dealerDiscounts){
                    finalPrice = Math.ceil((finalPrice/this.NDS.dealer)*100)/100
                } else {
                    finalPrice = Math.ceil((finalPrice/this.NDS.sub)*100)/100
                }
                return finalPrice
            }
            case 'CLASSIC': {
                priceTable = [
                    [251, 285, 298, 333, 351, 362, 433, 446, 491, 521, 556, 569],
                    [268, 303, 316, 351, 369, 415, 462,	475, 510, 545, 581,	592],
                    [333, 347, 362,	399, 418, 467, 521,	571, 585, 618, 656,	669],
                    [352, 365, 404,	417, 438, 486, 537,	585, 603, 672, 687,	701],
                    [357, 371, 420,	434, 488, 502, 591,	605, 620, 688, 702,	751],
                    [373, 421, 436,	450, 519, 534, 618,	632, 682, 716, 730,	778],
                    [395, 443, 459,	518, 538, 552, 640,	654, 704, 738, 798,	813],
                    [441, 490, 508,	570, 592, 635, 693,	710, 780, 818, 870,	886],
                    [499, 514, 531,	592, 615, 677, 736,	787, 804, 844, 907,	923],
                    [521, 537, 601,	616, 684, 700, 759,	822, 838, 924, 941,	1004]
                ]

                heightIndex = Math.ceil(item.height / 100) - 9
                widthIndex = Math.ceil(item.width / 500) - 3
                if(heightIndex < 0){
                    heightIndex = 0
                }
                if(widthIndex < 0){
                    widthIndex = 0
                }
                standartPrice = priceTable[heightIndex][widthIndex]
                break;
            }
            case 'MODERN': {
                priceTable = [
                    [495, 558, 580, 666, 706, 709],
                    [518, 581, 604, 689, 709, 793],
                    [603, 678, 704, 793, 817, 905],
                    [684, 707, 776, 823, 846, 935],
                    [724, 748, 837, 884, 971, 996],
                    [753, 841, 867, 914, 1022, 1047],
                    [815, 902, 929, 953, 1083, 1109],
                    [888, 979, 1011, 1143, 1172, 1250],
                    [1015, 1043, 1074, 1206, 1236, 1347],
                    [1045, 1074, 884, 1238, 1350, 1378]
                ]

                heightIndex = Math.ceil(item.height / 100) - 9
                widthIndex = Math.ceil(item.width / 500) - 3
                if(heightIndex < 0){
                    heightIndex = 0
                }
                if(widthIndex < 0){
                    widthIndex = 0
                }
                standartPrice = priceTable[heightIndex][widthIndex]
                break;
            }
            case 'Optima': {
                priceTable = [
                    [762, 1132, 1139, 1171, 1260, 1286, 1478, 1514, 1665, 1792, 2361, 2387, 2461, 2579, 2600, 2607, 3008],
                    [857, 1174, 1191, 1214, 1315, 1344, 1548, 1571, 1742, 1871, 2450, 2477, 2623, 2741, 2700, 2710, 3198],
                    [924, 1221, 1239, 1262, 1371, 1399, 1632, 1655, 1838, 2023, 2573, 2602, 2734, 2855, 2930, 2943, 3264],
                    [986, 1266, 1283, 1309, 1478, 1508, 1710, 1734, 1999, 2187, 2714, 2744, 2831, 2953, 3013, 3027, 3470],
                    [1197, 1477, 1413, 1441, 1611, 1645, 1855, 1881, 2122, 2334, 3024, 3057, 3107, 3232, 3296, 3314, 3877],
                    [1337, 1616, 1541, 1572, 1750, 1778, 2050, 2079, 2350, 2594, 3053, 3086, 3397, 3525, 3642, 3667, 4277]
                ]

                heightIndex = Math.ceil((item.height - 100) / 500) - 3
                widthIndex = Math.ceil(item.width / 500) - 8
                if(heightIndex < 0){
                    heightIndex = 0
                }
                if(widthIndex < 0){
                    widthIndex = 0
                }
                standartPrice = priceTable[heightIndex][widthIndex]
                break;
            }
            case 'Optima PLUS': {
                priceTable = [
                    [496, 695, 700, 728, 752, 768, 888, 1029, 1199, 1259, 1577, 1640, 1707, 1993, 2072, 2155, 2248],
                    [558, 722, 733, 757, 787, 804, 932, 1100, 1287, 1363, 1688, 1772, 1844, 2113, 2196, 2279, 2433],
                    [603, 753, 764, 779, 821, 839, 972, 1156, 1353, 1447, 1805, 1931, 2017, 2249, 2351, 2444, 2619],
                    [644, 781, 793, 831, 888, 907, 1033, 1238, 1461, 1548, 1923, 2056, 2138, 2362, 2479, 2578, 2750]
                ]

                heightIndex = Math.ceil((item.height - 100) / 500) - 3
                widthIndex = Math.ceil(item.width / 500) - 8
                if(heightIndex < 0){
                    heightIndex = 0
                }
                if(widthIndex < 0){
                    widthIndex = 0
                }
                standartPrice = priceTable[heightIndex][widthIndex]
                break;
            }

            case 'VIKTORY': {
                priceTable = [
                    [4219, 4347, 5713, 6071, 6964, 7907, 8388, 9666],
                    [4360, 4481, 5898, 6272, 7203, 8175, 8668, 9769],
                    [0, 4645, 6114, 6515, 7354, 8490, 9000, 10111],
                    [0, 4792, 6265, 0, 0, 0, 0, 0]
                ]

                heightIndex = Math.ceil((item.height - 100) / 500) - 8
                widthIndex = Math.ceil(item.width / 1000) - 5
                if(heightIndex < 0){
                    heightIndex = 0
                }
                if(widthIndex < 0){
                    widthIndex = 0
                }
                standartPrice = priceTable[heightIndex][widthIndex]
                break;
            }

            case 'Helix': {
                priceTable = [
                    [494, 701, 706],
                    [559, 729, 741],
                    [605, 761, 773],
                    [641, 782, 794, 822, 908],
                    [1020, 1155, 1203, 1216, 1277]
                ]

                heightIndex = Math.ceil((item.height - 100) / 500) - 3
                widthIndex = Math.ceil(item.width / 500) - 8
                if(heightIndex < 0){
                    heightIndex = 0
                }
                if(widthIndex < 0){
                    widthIndex = 0
                }
                standartPrice = priceTable[heightIndex][widthIndex]
                break;
            }

            case 'Гармония': {
                priceTable = [
                    [2165, 2509, 2621, 2914, 3020, 3358, 4052, 4393, 4734, 5077, 5419, 5760],
                    [2201, 2537, 2652, 2944, 3054, 3391, 4108, 4450, 4790, 5131, 5474, 5816],
                    [2238, 2563, 2683, 2974, 3085, 3422, 4161, 4502, 4845, 5186, 5528, 5868],
                    [2373, 2610, 2738, 3029, 3149, 3486, 4256, 4598, 4939, 5280, 5623, 5964],
                    [0, 2656, 2792, 3085, 3212, 3551, 4350, 4693, 5034, 5375, 5718, 6059],
                    [0, 0, 2897, 3191, 3337, 3675, 4906, 4866, 5207, 5550, 5890, 6233],
                    [0, 0, 0, 3523, 3687, 4026, 5153, 5596, 6038, 6481, 6926, 7367],
                    [0, 0, 0, 0, 3844, 4181, 5387, 5831, 6241, 6716, 7161, 7602],
                    [0, 0, 0, 0, 0, 4334, 0, 6069, 6512, 7524, 8536, 9548]
                ]

                switch(true) {
                    case item.width <= 3000 : {
                        widthIndex = 0
                        break;
                    }
                    case item.width <= 3600 : {
                        widthIndex = 1
                        break;
                    }
                    case item.width <= 4000 : {
                        widthIndex = 2
                        break;
                    }
                    case item.width <= 4800 : {
                        widthIndex = 3
                        break;
                    }
                    case item.width <= 5000 : {
                        widthIndex = 4
                        break;
                    }
                    default : {
                        widthIndex = Math.ceil((item.width) / 1000) - 1
                        break;
                    }
                }

                switch (true) {
                    case item.height <= 1600 : {
                        heightIndex = 0
                        break;
                    }
                    case item.height <= 1850 : {
                        heightIndex = 1
                        break;
                    }
                    case item.height <= 2100 : {
                        heightIndex = 2
                        break;
                    }
                    case item.height <= 2350 : {
                        heightIndex = 3
                        break;
                    }
                    case item.height <= 2600 : {
                        heightIndex = 4
                        break;
                    }
                    case item.height <= 3100 : {
                        heightIndex = 5
                        break;
                    }
                    case item.height <= 3600 : {
                        heightIndex = 6
                        break;
                    }
                    case item.height <= 4100 : {
                        heightIndex = 7
                        break;
                    }
                    case item.height <= 4350 : {
                        heightIndex = 8
                        break;
                    }
                    default: {
                        heightIndex = 8
                        break;
                    }
                }
                if(heightIndex < 0){
                    heightIndex = 0
                }
                if(widthIndex < 0){
                    widthIndex = 0
                }
                console.log(heightIndex,widthIndex, item.width, item.height)
                standartPrice = priceTable[heightIndex][widthIndex]
                break;
            }

            case 'Domea': {
                priceTable = [
                    [1347, 1351, 1355, 1358, 1361, 1365, 1368, 1372, 1375, 1378, 1382, 1385, 1388, 1392, 1395, 1398, 1402, 1405, 1410, 1413, 1416, 1653, 1656, 1660, 1663, 1667, 1670, 1673, 1677, 1680, 1684, 2020],
                    [0, 0, 1358, 1363, 1366, 1370, 1374, 1378, 1382, 1386, 1391, 1394, 1398, 1402, 1406, 1410, 1414, 1418, 1422, 1425, 1430, 1662, 1667, 1670, 1674, 1678, 1682, 1686, 1690, 1693, 1698, 2037],
                    [0, 0, 0, 0, 0, 1373, 1377, 1382, 1386, 1391, 1395, 1400, 1404, 1409, 1413, 1418, 1422, 1427, 1431, 1435, 1440, 1670, 1674, 1679, 1683, 1688, 1692, 1697, 1701, 1706, 1710, 2062],
                    [0,	0, 0, 0, 0, 0, 0, 1404, 1410, 1414, 1419, 1424, 1429, 1434, 1439, 1444, 1449, 1455, 1459, 1465, 1469, 1692, 1697, 1702, 1707, 1712, 1717, 1723, 1727, 1733, 1737, 2088],
                    [0,	0, 0, 0, 0, 0, 0, 0, 0, 0, 1422, 1428, 1432, 1438, 1443, 1449, 1455, 1460, 1466, 1471, 1477, 1697, 1702, 1708, 1714, 1719, 1725, 1730, 1736, 1741, 1746, 2141],
                    [0,	0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 1441, 1447, 1452, 1459, 1465, 1471, 1477, 1484, 1489, 1702, 1708, 1715, 1720, 1727, 1733, 1739, 1745, 1752, 1757, 2205],
                    [0,	0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 1476, 1483, 1489, 1496, 1503, 1510, 1717, 1724, 1730, 1737, 1744, 1751, 1757, 1764, 1771, 1778, 2311]
                    ]

                heightIndex = Math.ceil((item.height - 100) / 250) - 6
                widthIndex = Math.ceil(item.width / 100) - 20
                if(heightIndex < 0){
                    heightIndex = 0
                }
                if(widthIndex < 0){
                    widthIndex = 0
                }
                switch (30 < widthIndex && widthIndex <= 40) {
                    case true: {
                        standartPrice = priceTable[heightIndex][31]
                        break;
                    }
                    default: {
                        standartPrice = priceTable[heightIndex][widthIndex]
                        break;
                    }
                }
                break;
            }

            case 'ITALIA': {
                priceTable = [
                    [201, 249, 263,	312, 336, 374, 522],
                    [208, 262, 277,	328, 353, 395, 536],
                    [212, 272, 284,	341, 364, 410, 563],
                    [220, 282, 296,	356, 381, 431, 575],
                    [229, 295, 309,	373, 397, 451, 606]
                ]

                heightIndex = Math.ceil(item.height / 200) - 4

                switch(true) {
                    case item.width <= 1200 : {
                        widthIndex = 0
                        break;
                    }
                    case item.width <= 2000 : {
                        widthIndex = 1
                        break;
                    }
                    case item.width <= 2400 : {
                        widthIndex = 2
                        break;
                    }
                    case item.width <= 3000 : {
                        widthIndex = 3
                        break;
                    }
                    case item.width <= 3600 : {
                        widthIndex = 4
                        break;
                    }
                    case item.width <= 4000 : {
                        widthIndex = 5
                        break;
                    }
                    case item.width <= 5000 : {
                        widthIndex = 6
                        break;
                    }
                    default : {
                        widthIndex = 6
                        break;
                    }
                }
                if(heightIndex < 0){
                    heightIndex = 0
                }
                if(widthIndex < 0){
                    widthIndex = 0
                }
                standartPrice = priceTable[heightIndex][widthIndex]
                break;
            }

            case 'ASN 130 GPZ': {
                priceTable = [//height X width = priceEuro
                    [144, 177, 191, 197, 221, 221, 234, 236, 255, 275, 307, 311, 331, 341, 360, 368, 386, 405, 423, 442, 460],
                    [148, 178, 192, 203, 229, 247, 262, 269, 292, 314, 334, 346, 368, 379, 392, 392, 412, 432, 451, 471, 490],
                    [152, 182, 197, 206, 232, 250, 263, 287, 311, 334, 341, 371, 387, 409, 424, 428, 449, 470, 492, 513, 535],
                    [154, 185, 200, 219, 246, 253, 278, 303, 329, 354, 372, 396, 413, 437, 451, 475, 499, 523, 546, 570, 594],
                    [162, 194, 210, 229, 258, 278, 306, 324, 351, 370, 392, 418, 440, 460, 465, 495, 520, 544, 569, 594, 619],
                    [165, 196, 212, 243, 273, 295, 324, 343, 371, 392, 411, 434, 456, 472, 476, 507, 532, 557, 583, 608, 633],
                    [175, 210, 228, 260, 293, 316, 348, 368, 398, 416, 422, 450, 473, 489, 492, 518, 544, 570, 596, 622, 648],
                    [187, 224, 243, 278, 312, 337, 371, 392, 416, 420, 429, 469, 492, 515, 525, 552, 580, 608, 635, 663, 691],
                    [194, 233, 252, 288, 324, 350, 385, 407, 441, 446, 451, 493, 518, 535, 545, 574, 603, 631, 660, 689, 717],
                    [209, 251, 272, 311, 350, 358, 394, 439, 466, 481, 509, 525, 564, 597, 602, 626, 657, 689, 720, 751, 783],
                    [220, 264, 286, 327, 368, 377, 414, 462, 500, 522, 535, 565, 600, 635, 648, 658, 683, 716, 748, 781, 813],
                    [228, 274, 296, 339, 370, 391, 430, 479, 519, 553, 567, 599, 636, 674, 672, 691, 717, 751, 785, 819, 853],
                    [241, 289, 313, 358, 391, 412, 454, 505, 547, 577, 593, 618, 649, 688, 709, 729, 757, 793, 829, 865, 901],
                    [258, 310, 339, 387, 411, 434, 477, 532, 558, 601, 637, 651, 691, 699, 712, 740, 748, 764, 809, 822, 857],
                    [271, 325, 356, 407, 432, 456, 501, 547, 586, 625, 655, 699, 726, 734, 747, 777, 786, 802, 850, 864, 900],
                    [284, 341, 373, 426, 452, 477, 525, 555, 595, 633, 671, 708, 752, 769, 783, 814, 823, 840, 890, 905, 942],
                    [297, 356, 390, 446, 473, 478, 526, 561, 608, 655, 701, 748, 795, 804, 819, 851],
                    [310, 372, 407, 465, 484, 488, 537, 586, 634, 660, 707, 754 ,802, 819],
                    [323, 387, 424, 484, 493, 508, 559, 610, 661, 688, 720, 768]
                ]

                heightIndex = Math.ceil(item.height / 200) - 7
                widthIndex = Math.ceil(item.width / 200) - 5
                if(heightIndex < 0){
                    heightIndex = 0
                }
                if(widthIndex < 0){
                    widthIndex = 0
                }
                standartPrice = priceTable[heightIndex][widthIndex]
                break;
            }
            case 'ASN 110 cab':{
                priceTable = [//height X width = priceEuro
                    [83, 100, 117, 120, 135, 139, 147, 148, 161, 173, 175, 178, 189, 200, 211, 223],
                    [84, 101, 118, 124, 140, 155, 165, 169, 184, 198, 200, 203, 216, 229, 242, 254],
                    [86, 103, 120, 126, 142, 157, 165, 180, 195, 210, 215, 229, 243, 258, 272, 286],
                    [87, 105, 122, 134, 150, 159, 175, 191, 207, 223, 238, 254, 270, 286, 302, 318],
                    [92, 110, 129, 140, 157, 175, 192, 210, 227, 245, 262, 280, 297, 315, 332, 350],
                    [94, 111, 130, 148, 167, 185, 204, 222, 241, 259, 278, 296, 315, 333, 352, 371],
                    [99, 119, 139, 159, 179, 199, 219, 238, 258, 278, 298, 318, 338, 358, 378, 397],
                    [106, 127, 148, 169, 191, 212, 233, 254, 275, 297, 318, 339, 360, 381, 403, 424],
                    [110, 132, 154, 176, 198, 220, 242, 264, 286, 308, 330, 352, 374, 396, 418, 440]
                ]

                heightIndex = Math.ceil(item.height / 200) - 7
                widthIndex = Math.ceil(item.width / 200) - 5
                if(heightIndex < 0){
                    heightIndex = 0
                }
                if(widthIndex < 0){
                    widthIndex = 0
                }
                standartPrice = priceTable[heightIndex][widthIndex]
                break;
            }
            case 'ASN 130 GPZ TENS':{
                priceTable = [//height X width = priceEuro
                    [150, 184, 199, 204, 230, 230, 243, 245, 265, 286, 318, 323, 344, 354, 374, 382, 401, 421, 440, 459, 478],
                    [154, 185, 200, 211, 238, 256, 273, 280, 303, 326, 347, 360, 382, 393, 407, 408, 428, 448, 469, 489, 509],
                    [157, 189, 204, 214, 241, 260, 273, 298, 323, 347, 354, 386, 402, 425, 440, 444, 466, 489, 511, 533, 555],
                    [160, 192, 208, 227, 256, 263, 289, 315, 341, 368, 386, 412, 429, 454, 469, 494, 518, 543, 568, 592, 617],
                    [168, 202, 219, 238, 268, 289, 318, 336, 364, 384, 407, 434, 457, 478, 483, 514, 540, 566, 591, 617, 643],
                    [172, 204, 221, 252, 284, 306, 337, 356, 386, 407, 427, 450, 473, 490, 494, 526, 553, 579, 605, 632, 658],
                    [182, 219, 237, 270, 304, 328, 361, 382, 414, 432, 438, 467, 491, 508, 511, 538, 565, 592, 619, 646, 673],
                    [194, 233, 252, 288, 324, 350, 385, 407, 432, 436, 446, 487, 512, 535, 545, 574, 603, 631, 660, 689, 717],
                    [202, 242, 262, 300, 337, 364, 400, 423, 458, 463, 469, 512, 538, 556, 566, 596, 626, 656, 686, 715, 745],
                    [217, 261, 283, 323, 363, 372, 409, 456, 484, 499, 529, 545, 586, 621, 625, 650, 683, 715, 748, 781, 813],
                    [229, 274, 297, 340, 382, 391, 431, 480, 520, 542, 556, 587, 623, 660, 673, 684, 710, 743, 777, 811, 845],
                    [237, 285, 308, 352, 384, 406, 446, 497, 539, 574, 590, 622, 661, 700, 698, 718, 745, 780, 815, 851, 886],
                    [250, 300, 325, 372, 406, 428, 471, 525, 569, 600, 615, 642, 675, 714, 737, 758, 786, 823, 861, 898, 936],
                    [268, 322, 352, 402, 427, 451, 496, 552, 580, 625, 662, 676, 718, 726, 739, 769, 777, 794, 841, 854, 890],
                    [282, 338, 370, 423, 449, 473, 521, 568, 609, 649, 680, 726, 754, 763, 776, 807, 816, 833, 883, 897, 934],
                    [295, 354, 387, 443, 470, 496, 546, 576, 618, 658, 697, 735, 781, 799, 813, 846, 855, 873, 925, 940, 979],
                    [308, 370, 405, 463, 491, 497, 546, 583, 632, 680, 729, 777, 826, 835, 850, 884, 894, 913, 967, 983, 1023],
                    [322, 386, 423, 483, 502, 507, 558, 608, 659, 686, 735, 784, 833, 851, 866, 877, 885, 915, 956, 998, 1025],
                    [335, 402, 440, 503, 513, 528, 581, 634, 686, 714, 748, 797, 847, 865, 902, 914, 922, 953, 996, 1039, 1068]
                ]

                heightIndex = Math.ceil(item.height / 200) - 7
                widthIndex = Math.ceil(item.width / 200) - 5
                if(heightIndex < 0){
                    heightIndex = 0
                }
                if(widthIndex < 0){
                    widthIndex = 0
                }
                standartPrice = priceTable[heightIndex][widthIndex]
                break;
            }
            case 'Тент на люверсах':{
                let S = (item.height * item.width)/1000000
                let P = ((item.height + item.width)/1000)*2
                let N = Math.ceil((P/0.4)*3.5)
                standartPrice = S*22+14+N
                break;
            }
            default: {
                standartPrice = 0
            }
        }

        switch (item.factory) {
            case 'Crystal': {
               // if (item.model !== 'Тент на люверсах' ) {
                    factoryPrice = Math.ceil((standartPrice / 100) * 10)
                //} else {
                    //factoryPrice = 0
                //}
                break;
            }
            case 'COPACO': {
                factoryPrice = Math.ceil((standartPrice / 100) * 7)
                break;
            }
            case 'Dickson акриловые ткани': {
                factoryPrice = 0
                break;
            }
            default: {
                factoryPrice = 0
            }
        }

        switch (item.model) {
            case 'Тент на люверсах':
                colorPrice = 0
                break;
            case 'CLASSIC': case 'MODERN': case 'VIKTORY': case 'Helix': case 'Optima': case 'Optima PLUS': case 'ITALIA': case 'Domea': case 'Гармония': case 'ASN 110 cab':
                if (item.color === 'Ral 9016(белый)') {
                    colorPrice = 0
                } else {
                    colorPrice = Math.ceil((standartPrice / 100) * 10)
                    this.colored = true
                }
                break
            default: {
                switch (item.color) {
                    case 'Ral 9016(белый)': case 'Ral 9004(черный)': case 'Ral 8017(коричневый)':case 'Ral 1024(бежевый)':case 'Ral 7024(антрацит)':case 'Ral 1013(жемчужный)':
                        colorPrice = 0
                        break
                    default:
                        colorPrice = Math.ceil((standartPrice / 100) * 10)
                        this.colored = true
                }
            }

        }

        switch (this.electricDriverSelection(item.width, item.height, item.controlsFactory, item.control, item.controlAcceptor, item.model)) {
            case 'электропривод 220 V до 5м.кв': {
                electricDriverPrice = 39
                break;
            }
            case 'электропривод 220 V до 10м.кв': {
                electricDriverPrice = 56
                break;
            }
            case 'электропривод 220 V до 20м.кв': {
                electricDriverPrice = 96
                break;
            }
            case 'электропривод 220 V до 25м.кв': {
                electricDriverPrice = 107
                break;
            }
            case 'электропривод 220 V BOOST 15 8/12 KIT60': {
                electricDriverPrice = 52
                break;
            }
            case 'электропривод 220 V BOOST 35 20/12 KIT60': {
                electricDriverPrice = 63
                break;
            }
            case 'электропривод 220 V Sirius LT 60 80/12': {
                electricDriverPrice = 175
                break;
            }
            case 'электропривод 220 V ALTUS 50 RTS 20/17 RRF 1M – BAR': {
                electricDriverPrice = 196
                break;
            }
            case 'ручное управление': {
                switch (item.model){
                    case 'ASN 130 GPZ': case 'ASN 110 cab': case 'ASN 130 GPZ TENS': {
                        electricDriverPrice = 51
                        break
                    }
                    default: {
                        electricDriverPrice = 0
                        break
                    }
                }
                break
            }
            case 'Без управления': {
                electricDriverPrice = 0
                break;
            }
            case 'электропривод 220 V ALTUS 50 RTS 40/17 RRF 1M - BAR': {
                electricDriverPrice = 244
                break;
            }
            case 'электропривод 220 V ALTUS 50 RTS 50/12 RRF 1M - BAR': {
                electricDriverPrice = 265
                break;
            }
            case 'электропривод 220 V, NM1/50-12 KIT60': {
                electricDriverPrice = 101
                break;
            }
            case 'электропривод 220 V, NM2-PP/80-12 KIT70': {
                electricDriverPrice = 108
                break;
            }
            case 'электропривод 220 V, NM2-PP/100-12 KIT102': {
                electricDriverPrice = 134
                break;
            }
            case 'электропривод LT60 SIRIUS 80/12 HNK с аварийным подъемом': {
                electricDriverPrice = 208
                break;
            }
            case 'электропривод Titan 100/12 NHK': {
                electricDriverPrice = 236
                break;
            }
            case 'электропривод LT60 TAURUS NHK 120/12 с аварийным подъемом': {
                electricDriverPrice = 251
                break;
            }
            case 'электропривод ALTUS до 8м. со встроенным приемником': {
                electricDriverPrice = 265
                break;
            }
            case 'электропривод ALTUS до 10м. со встроенным приемником': {
                electricDriverPrice = 329
                break;
            }
            case 'электропривод ALTUS до 14м. со встроенным приемником': {
                electricDriverPrice = 373
                break;
            }
            case 'Нет привода под заданые размеры!': {
                electricDriverPrice = 0
                break;
            }
            default: {
                electricDriverPrice = 0
            }
        }
        const constructionPrice = ((standartPrice + colorPrice + factoryPrice)*(1 - discount/100))*RecPriceCof.model
        const automationPrice = (electricDriverPrice * (1 - autDiscount/100))*RecPriceCof.automation
        finalPrice = constructionPrice + automationPrice;
        console.log(standartPrice, colorPrice, factoryPrice, electricDriverPrice, automationPrice, constructionPrice)

        if(dealerDiscounts){
            finalPrice = Math.ceil((finalPrice/this.NDS.dealer)*100)/100
        } else {
            finalPrice = Math.ceil((finalPrice/this.NDS.sub)*100)/100
        }
        return finalPrice

    }
    setNDS(country: string, type: string, role: string) {
        if(role === 'Subdealer' && country === 'Казахстан'){
            return {
                sub: 1.07,
                dealer: 1.2
            }
        }else if (country === 'Беларусь') {
            return {
                sub: 1,
                dealer: 1
            }
        } else if (type === 'Физическое лицо' || role === 'Subdealer') {
            return {
                sub: 1,
                dealer: 1.2
            }
        }else {
            return {
                sub: 1.2,
                dealer: 1.2
            }
        }
    }

    electricDriverSelection(width: number, height: number, factory: string, type: string, somfyAcceptor: string, model: string): string {
        if (type === 'Автоматическое') {
            switch (model) {
                case 'Optima': case 'Optima PLUS': case 'Helix': case 'VIKTORY': case 'ITALIA': {
                    switch (factory) {
                        case 'AC инженеринг': {
                            if (width <= 8000) {
                                return 'электропривод 220 V, NM1/50-12 KIT60'
                            } else if (width <= 10000 && width > 8000) {
                                return 'электропривод 220 V, NM2-PP/80-12 KIT70'
                            } else if (width <= 14000 && width > 10000) {
                                return 'электропривод 220 V, NM2-PP/100-12 KIT102'
                            } else {
                                return 'Нет привода под заданые размеры!'
                            }
                        }
                        case 'Somfy': {
                            if (somfyAcceptor === 'Без приемника') {
                                if (width <= 8000) {
                                    return 'электропривод LT60 SIRIUS 80/12 HNK с аварийным подъемом'
                                } else if (width <= 10000 && width > 8000) {
                                    return 'электропривод Titan 100/12 NHK'
                                } else if (width <= 14000 && width > 10000) {
                                    return 'электропривод LT60 TAURUS NHK 120/12 с аварийным подъемом'
                                } else {
                                    return 'Нет привода под заданые размеры!'
                                }
                            } else if (somfyAcceptor === 'Cо встроенным приемником') {
                                if (width <= 8000) {
                                    return 'электропривод LT60 SIRIUS 80/12 HNK с аварийным подъемом'
                                } else if (width <= 10000 && width > 8000) {
                                    return 'электропривод Titan 100/12 NHK'
                                } else if (width <= 14000 && width > 10000) {
                                    return 'электропривод LT60 TAURUS NHK 120/12 с аварийным подъемом'
                                } else {
                                    return 'Нет привода под заданые размеры!'
                                }
                            }
                        }
                    }
                    break;
                }
                case 'Гармония': case 'Domea': {
                    if (width <= 8000) {
                        return 'электропривод ALTUS до 8м. со встроенным приемником'
                    } else if (width <= 10000 && width > 8000) {
                        return 'электропривод ALTUS до 10м. со встроенным приемником'
                    } else if (width <= 14000 && width > 10000) {
                        return 'электропривод ALTUS до 14м. со встроенным приемником'
                    } else {
                        return 'Нет привода под заданые размеры!'
                    }
                }
                default: {
                    const volume = height * width
                    switch (factory) {
                        case 'AC инженеринг': {
                            if (volume <= 5000000) {
                                return 'электропривод 220 V до 5м.кв'
                            } else if (volume <= 10000000 && volume > 5000000) {
                                return 'электропривод 220 V до 10м.кв'
                            } else if (volume <= 20000000 && volume > 10000000) {
                                return 'электропривод 220 V до 20м.кв'
                            } else if (volume <= 25000000 && volume > 20000000) {
                                return 'электропривод 220 V до 25м.кв'
                            } else {
                                return 'Нет привода под заданые размеры!'
                            }
                        }
                        case 'Somfy': {
                            if (somfyAcceptor === 'Без приемника') {
                                if (volume <= 7500000) {
                                    return 'электропривод 220 V BOOST 15 8/12 KIT60'
                                } else if (volume <= 12000000 && volume > 7500000) {
                                    return 'электропривод 220 V BOOST 35 20/12 KIT60'
                                } else if (volume <= 25000000 && volume > 12000000) {
                                    return 'электропривод 220 V Sirius LT 60 80/12'
                                } else {
                                    return 'Нет привода под заданые размеры!'
                                }
                            } else if (somfyAcceptor === 'Cо встроенным приемником') {
                                if (volume <= 12000000) {
                                    return 'электропривод 220 V ALTUS 50 RTS 20/17 RRF 1M – BAR'
                                } else if (volume <= 20000000 && volume > 12000000) {
                                    return 'электропривод 220 V ALTUS 50 RTS 40/17 RRF 1M - BAR'
                                } else if (volume <= 25000000 && volume > 20000000){
                                    return 'электропривод 220 V ALTUS 50 RTS 50/12 RRF 1M - BAR'
                                } else {
                                    return 'Нет привода под заданые размеры!'
                                }
                            }
                        }
                    }
                }
            }
        } else if (type === 'Ручное'){
            return 'ручное управление'
        }
        return 'Без управления'
    }

    async dataInitialization(data: FormValues) {

        let items: Item[] = []
        let additionalItems: AdditionalItem[] = []

        for (let item of data.items) {
            let itemName: string
            let itemMontageType: string
            if (item.model === 'Выставочный стенд') {
                itemName = 'Выставочный стенд'
                itemMontageType = ''
            } else {
                switch (item.model) {
                    case "ASN 130 GPZ":
                        itemName = 'Вертикальные маркизы (экраны) ASN 130 GPZ.\n'
                        break
                    case "ASN 110 cab":
                        itemName = 'Вертикальные маркизы (экраны) ASN 110 cabrio.\n'
                        break
                    case "ASN 130 GPZ TENS":
                        itemName = 'Вертикальные маркизы (экраны) ASN 130 GPZ TENZ.\n'
                        break
                    case "Тент на люверсах":
                        itemName = 'Тент на люверсах.\n'
                        break
                    case 'Helix':
                        itemName = 'Локтевые маркизы Helix.\n'
                        break
                    case 'Optima':
                        itemName = 'Локтевые маркизы Optima.\n'
                        break
                    case 'Optima PLUS':
                        itemName = 'Локтевые маркизы Optima PLUS.\n'
                        break
                    case 'VIKTORY':
                        itemName = 'Локтевые маркизы VIKTORY.\n'
                        break
                    case 'Гармония':
                        itemName = 'Локтевые маркизы Гармония.\n'
                        break
                    case 'Domea':
                        itemName = 'Локтевые маркизы Domea.\n'
                        break
                    case 'CLASSIC':
                        itemName = 'Купольные маркизы CLASSIC.\n'
                        break
                    case 'MODERN':
                        itemName = 'Купольные маркизы MODERN.\n'
                        break
                    case 'ITALIA':
                        itemName = 'Витринные маркизы ITALIA.\n'
                        break
                    default:
                        itemName = 'Маркиза.\n'
                }
                itemMontageType = 'стена, на проем.'
            }
            if (item.mountType) {
                itemMontageType = item.mountType
            }

            const electricDriver = this.electricDriverSelection(item.width, item.height, item.controlsFactory, item.control, item.controlAcceptor, item.model)

            items.push({
                name: itemName,
                width: item.width,
                height: item.height,
                montageType: itemMontageType,
                tentManufactor: item.factory,
                tentArticle: item.article,
                tentControlSide: item.article2,
                automaticManufacture: item.automaticFactory,
                electricDriverManufacture: item.controlsFactory,
                electricDriver: electricDriver,
                electricDriverSide: item.controlSide,
                color: item.color,
                volan: item.volan,
                SI: 'компл.',
                priceEuro: this.countPriceOfItem(item),
                dealerPrice: this.countPriceOfItem(item, this.userData.dealerDiscounts),
                recommendedPriceEuro: this.countPriceOfItem(item, undefined,true),
                notes: item.notes
            })
            const RecPriceCof = this.GetRecomendedPriceMultipler(item.model)
            if (itemName !== 'Выставочный стенд' && itemName !== 'Тент на люверсах.\n') {
                if (item.automaticFactory === 'AC инженеринг' || item.controlsFactory === 'AC инженеринг') {
                    if(item.automaticFactory !== 'Без автоматики') {
                        switch (item.model) {
                            case 'ASN 130 GPZ':
                            case 'ASN 110 cab':
                            case 'ASN 130 GPZ TENS': {
                                additionalItems.push({
                                    description: 'Приемник одноканальный Radio 8113 micro',
                                    quantity: 1,
                                    SI: 'шт.',
                                    priceEuro:
                                        Math.ceil((25 * (1 - this.userData.autDiscount / 100) / this.NDS.sub) * 100) / 100,
                                    dealerPrice:
                                        Math.ceil((25 * (1 - this.userData.dealerDiscounts.autDiscount / 100) / this.NDS.dealer) * 100) / 100,
                                    recommendedPriceEuro:
                                        Math.ceil(((25 / this.NDS.sub) * RecPriceCof.automation) * 100) / 100,
                                    ref: items.length - 1
                                })
                                break;
                            }
                            default: {
                                additionalItems.push({
                                    description: 'Приемник одноканальный Radio 8113 IP65',
                                    quantity: 1,
                                    SI: 'шт.',
                                    priceEuro:
                                        Math.ceil((32 * (1 - this.userData.autDiscount / 100) / this.NDS.sub) * 100) / 100,
                                    dealerPrice:
                                        Math.ceil((32 * (1 - this.userData.dealerDiscounts.autDiscount / 100) / this.NDS.dealer) * 100) / 100,
                                    recommendedPriceEuro:
                                        Math.ceil(((32 / this.NDS.sub) * RecPriceCof.automation) * 100) / 100,
                                    ref: items.length - 1
                                })
                            }
                        }
                    }
                    if (item.pilotQuantity > 0) {
                        switch (item.pilotType){
                            case 'Одноканальный': {
                                additionalItems.push({
                                    description: 'Пульт-брелок одноканальный Radio 8101-1М',
                                    quantity: item.pilotQuantity,
                                    SI: 'шт.',
                                    priceEuro:
                                        Math.ceil((13 * (1 - this.userData.autDiscount/100)/this.NDS.sub)*100)/100,
                                    dealerPrice:
                                        Math.ceil((13 * (1 - this.userData.dealerDiscounts.autDiscount/100)/this.NDS.dealer)*100)/100,
                                    recommendedPriceEuro:
                                        Math.ceil(((13/this.NDS.sub)*RecPriceCof.automation)*100)/100,
                                    ref: items.length-1
                                })
                                break;
                            }
                            case 'Пятиканальный': {
                                additionalItems.push({
                                    description: 'Пульт пятиканальный Radio 8101-5',
                                    quantity: item.pilotQuantity,
                                    SI: 'шт.',
                                    priceEuro:
                                        Math.ceil((16 * (1 - this.userData.autDiscount/100)/this.NDS.sub)*100)/100,
                                    dealerPrice:
                                        Math.ceil((16 * (1 - this.userData.dealerDiscounts.autDiscount/100)/this.NDS.dealer)*100)/100,
                                    recommendedPriceEuro:
                                        Math.ceil(((16/this.NDS.sub)*RecPriceCof.automation)*100)/100,
                                    ref: items.length-1
                                })
                                break;
                            }
                            case 'Пятнадцатиканальный': {
                                additionalItems.push({
                                    description: 'Пульт пятнадцатиканальный Radio 8101-15',
                                    quantity: item.pilotQuantity,
                                    SI: 'шт.',
                                    priceEuro:
                                        Math.ceil((24 * (1 - this.userData.autDiscount/100)/this.NDS.sub)*100)/100,
                                    dealerPrice:
                                        Math.ceil((24 * (1 - this.userData.dealerDiscounts.autDiscount/100)/this.NDS.dealer)*100)/100,
                                    recommendedPriceEuro:
                                        Math.ceil(((24/this.NDS.sub)*RecPriceCof.automation)*100)/100,
                                    ref: items.length-1
                                })
                                break;
                            }
                        }
                    }
                } else if (item.automaticFactory === 'Somfy' || item.controlsFactory === 'Somfy') {
                    if(item.automaticFactory !== 'Без автоматики') {
                        if (item.model !== 'Domea' && item.model !== 'Гармония' && item.controlAcceptor !== 'Cо встроенным приемником') {
                            additionalItems.push({
                                description: 'Радиоприемник Somfy RTS универсальный в коробке монтажной',
                                quantity: 1,
                                SI: 'шт.',
                                priceEuro:
                                    Math.ceil((104 * (1 - this.userData.autDiscount / 100) / this.NDS.sub) * 100) / 100,
                                dealerPrice:
                                    Math.ceil((104 * (1 - this.userData.dealerDiscounts.autDiscount / 100) / this.NDS.dealer) * 100) / 100,
                                recommendedPriceEuro:
                                    Math.ceil(((104 / this.NDS.sub) * RecPriceCof.automation) * 100) / 100,
                                ref: items.length - 1
                            })
                        }
                    }
                    if (item.pilotQuantity > 0) {
                        switch (item.pilotType){
                            case 'Одноканальный': {
                                additionalItems.push({
                                    description: 'Пульт Somfy Situo 1 RTS Pure',
                                    quantity: item.pilotQuantity,
                                    SI: 'шт.',
                                    priceEuro:
                                        Math.ceil((35 * (1 - this.userData.autDiscount/100)/this.NDS.sub)*100)/100,
                                    dealerPrice:
                                        Math.ceil((35 * (1 - this.userData.dealerDiscounts.autDiscount/100)/this.NDS.dealer)*100)/100,
                                    recommendedPriceEuro:
                                        Math.ceil(((35/this.NDS.sub)*RecPriceCof.automation)*100)/100,
                                    ref: items.length-1
                                })
                                break;
                            }
                            case 'Пятиканальный': {
                                additionalItems.push({
                                    description: 'Пульт Somfy Situo 5 RTS Pure',
                                    quantity: item.pilotQuantity,
                                    SI: 'шт.',
                                    priceEuro:
                                        Math.ceil((69 * (1 - this.userData.autDiscount/100)/this.NDS.sub)*100)/100,
                                    dealerPrice:
                                        Math.ceil((69 * (1 - this.userData.dealerDiscounts.autDiscount/100)/this.NDS.dealer)*100)/100,
                                    recommendedPriceEuro:
                                        Math.ceil(((69/this.NDS.sub)*RecPriceCof.automation)*100)/100,
                                    ref: items.length-1
                                })
                                break;
                            }
                            case 'Пятнадцатиканальный': {
                                additionalItems.push({
                                    description: 'Пульт TELIS 16 RTS Pure',
                                    quantity: item.pilotQuantity,
                                    SI: 'шт.',
                                    priceEuro:
                                        Math.ceil((127 * (1 - this.userData.autDiscount/100)/this.NDS.sub)*100)/100,
                                    dealerPrice:
                                        Math.ceil((127 * (1 - this.userData.dealerDiscounts.autDiscount/100)/this.NDS.dealer)*100)/100,
                                    recommendedPriceEuro:
                                        Math.ceil(((127/this.NDS.sub)*RecPriceCof.automation)*100)/100,
                                    ref: items.length-1
                                })
                                break;
                            }
                        }
                    }

                }
                if (item.automaticAddition === 'Датчик ветра') {
                    additionalItems.push({
                        description: 'Датчик ветра Somfy Eolis Sensor RTS (датчик ветра, радиопередатчик)',
                        quantity: 1,
                        SI: 'шт.',
                        priceEuro:
                            Math.ceil((109 * (1 - this.userData.autDiscount/100)/this.NDS.sub)*100)/100,
                        dealerPrice:
                            Math.ceil((109 * (1 - this.userData.dealerDiscounts.autDiscount/100)/this.NDS.dealer)*100)/100,
                        recommendedPriceEuro:
                            Math.ceil(((109/this.NDS.sub)*RecPriceCof.automation)*100)/100,
                        ref: items.length-1
                    })
                }
                if (item.automaticAddition === 'Автономный датчик ветра') {
                    additionalItems.push({
                        description: 'Датчик ветра автономный EOLIS 3D WIREFREE RTS',
                        quantity: 1,
                        SI: 'шт.',
                        priceEuro:
                            Math.ceil((107 * (1 - this.userData.autDiscount/100)/this.NDS.sub)*100)/100,
                        dealerPrice:
                            Math.ceil((107 * (1 - this.userData.dealerDiscounts.autDiscount/100)/this.NDS.dealer)*100)/100,
                        recommendedPriceEuro:
                            Math.ceil(((107/this.NDS.sub)*RecPriceCof.automation)*100)/100,
                        ref: items.length-1
                    })
                }

                if (item.server === 'Добавить') {
                    additionalItems.push({
                        description: 'Устройство центральное 8767',
                        quantity: 1,
                        SI: 'шт.',
                        priceEuro:
                            Math.ceil((40 * (1 - this.userData.autDiscount/100)/this.NDS.sub)*100)/100,
                        dealerPrice:
                            Math.ceil((40 * (1 - this.userData.dealerDiscounts.autDiscount/100)/this.NDS.dealer)*100)/100,
                        recommendedPriceEuro:
                            Math.ceil(((40/this.NDS.sub)*RecPriceCof.automation)*100)/100,
                        ref: items.length-1
                    })
                }

                if (item.swb === 'SWB') {
                    additionalItems.push({
                        description: 'Выключатель клавишный SWB для накладного монтажа',
                        quantity: 1,
                        SI: 'шт.',
                        priceEuro:
                            Math.ceil((3.5 * (1 - this.userData.autDiscount/100)/this.NDS.sub)*100)/100,
                        dealerPrice:
                            Math.ceil((3.5 * (1 - this.userData.dealerDiscounts.autDiscount/100)/this.NDS.dealer)*100)/100,
                        recommendedPriceEuro:
                            Math.ceil(((3.5/this.NDS.sub)*RecPriceCof.automation)*100)/100,
                        ref: items.length-1
                    })
                }
            } else if (itemName === 'Тент на люверсах.\n'){
                if(item.belts !== 0) {
                    additionalItems.push({
                        description: 'Ремешок 350 мм.',
                        quantity: item.belts,
                        SI: 'шт.',
                        priceEuro:
                            Math.ceil((2.7 * (1 - this.userData.discount/100)/this.NDS.sub)*100)/100,
                        dealerPrice:
                            Math.ceil((2.7 * (1 - this.userData.dealerDiscounts.discount/100)/this.NDS.dealer)*100)/100,
                        recommendedPriceEuro:
                            Math.ceil(((2.7/this.NDS.sub)*RecPriceCof.model)*100)/100,
                        ref: items.length-1
                    })
                }

                let zipSize = item.zip
                if(zipSize !== 0 && !isNaN(zipSize)){
                    additionalItems.push({
                        description: 'Молния',
                        quantity: zipSize,
                        SI: 'м.',
                        priceEuro:
                            Math.ceil((7 * (1 - this.userData.discount/100)/this.NDS.sub)*100)/100,
                        dealerPrice:
                            Math.ceil((7 * (1 - this.userData.dealerDiscounts.discount/100)/this.NDS.dealer)*100)/100,
                        recommendedPriceEuro:
                            Math.ceil(((7/this.NDS.sub)*RecPriceCof.model)*100)/100,
                        ref: items.length-1
                    })
                }

            }
            if(item.model === 'Helix' || item.model === 'Optima') {
                if(item.width-500 < item.height){
                    additionalItems.push({
                        description: 'Комплект для смещения локтей',
                        quantity: 1,
                        SI: 'м.',
                        priceEuro:
                            this.colored ? Math.ceil((84*1.1 * (1 - this.userData.discount/100)/this.NDS.sub)*100)/100 : Math.ceil((84 * (1 - this.userData.autDiscount/100)/this.NDS.sub)*100)/100,
                        dealerPrice:
                            this.colored ? Math.ceil((84*1.1 * (1 - this.userData.dealerDiscounts.discount/100)/this.NDS.dealer)*100)/100 : Math.ceil((84 * (1 - this.userData.dealerDiscounts.autDiscount/100)/this.NDS.dealer)*100)/100,
                        recommendedPriceEuro:
                        //ASK
                            this.colored ? Math.ceil((((84*1.1)/this.NDS.sub)*RecPriceCof.model)*100)/100 : Math.ceil(((84/this.NDS.sub)*RecPriceCof.model)*100)/100,
                        ref: items.length-1
                    })
                }
            }

            if(item.ads === 'Добавить'){
                const square = Math.ceil((item.width * item.height)/1000000)
                //const price = square * 32
                additionalItems.push({
                    description: `Нанесение рекламы ${square}м.кв (ЦЕНА СОГЛАСОВЫВАЕТСЯ ДОПОЛНИТЕЛЬНО)`,
                    quantity: 1,
                    SI: 'м.',
                    priceEuro:
                        0,
                    dealerPrice:
                        0,
                    recommendedPriceEuro:
                        0,
                    ref: items.length-1
                })
            }

            if(item.led === 'Добавить'){
                let price = 0
                switch (item.model) {
                    case 'Optima': {
                        if (item.width <= 6000){
                            price = 371
                        } else if (item.width <= 8000){
                            price = 456
                        } else if (item.width <= 10000){
                            price = 615
                        } else if (item.width <= 12000){
                            price = 668
                        }
                        break
                    }
                    case 'Гармония': {
                        if (item.width <= 7000 && item.height <= 3100){
                            price = 350
                        } else if (item.width <= 7000 && item.height <= 4350){
                            price = 371
                        } else if (item.width <= 12000 && item.height <= 3100){
                            price = 509
                        } else if (item.width <= 12000 && item.height <= 4350){
                            price = 689
                        }
                        break
                    }
                    case 'VIKTORY': {
                        if (item.width <= 7000 && item.height <= 4600){
                            price = 392
                        } else if (item.width <= 7000 && item.height <= 5650){
                            price = 435
                        } else if (item.width <= 12000 && item.height <= 4600){
                            price = 572
                        } else if (item.width <= 12000 && item.height <= 5650){
                            price = 742
                        }
                        break
                    }
                    case 'Domea': {
                        if (item.width <= 6000 && item.height <= 3100){
                            price = 350
                        }
                        break
                    }
                    case 'Helix': {
                        price = 371
                        break
                    }
                }


                additionalItems.push({
                    description: `Комплект LED освещения`,
                    quantity: 1,
                    SI: 'м.',
                    priceEuro:
                        Math.ceil((price * (1 - this.userData.autDiscount/100)/this.NDS.sub)*100)/100,
                    dealerPrice:
                        Math.ceil((price * (1 - this.userData.dealerDiscounts.autDiscount/100)/this.NDS.dealer)*100)/100,
                    recommendedPriceEuro:
                    //ASK
                        Math.ceil(((price/this.NDS.sub)*RecPriceCof.automation)*100)/100,
                    ref: items.length-1
                })
            }

            if(item.cap === 'Добавить'){
                let price = 0
                switch (item.model) {
                    case 'Helix': {
                        if (item.width <= 3500) {
                            price = 156
                        } else {
                            price = 277
                        }
                        break
                    }
                    case 'VIKTORY': {
                        if (item.width <= 6000) {
                            price = 509
                        } else {
                            price = 965
                        }
                        break
                    }
                    case 'Optima': {
                        if (item.width <= 3500) {
                            price = 156
                        } else if (item.width <= 7000) {
                            price = 277
                        } else if (item.width <= 10500){
                            price = 345
                        } else {
                            price = 469
                        }
                        break
                    }
                    case 'Optima PLUS': {
                        if (item.width <= 3500) {
                            price = 156
                        } else if (item.width <= 7000) {
                            price = 277
                        } else if (item.width <= 10500){
                            price = 345
                        } else {
                            price = 469
                        }
                        break
                    }
                }

                price = this.colored ? price*1.1 : price

                additionalItems.push({
                    description: `Комплект укрывающей крышки`,
                    quantity: 1,
                    SI: 'м.',
                    priceEuro:
                        Math.ceil((price * (1 - this.userData.discount/100)/this.NDS.sub)*100)/100,
                    dealerPrice:
                        Math.ceil((price * (1 - this.userData.dealerDiscounts.discount/100)/this.NDS.dealer)*100)/100,
                    recommendedPriceEuro:
                    //ASK
                        Math.ceil(((price/this.NDS.sub)*RecPriceCof.model)*100)/100,
                    ref: items.length-1
                })
            }
        }
        return {
            items: items,
            additionalItems: additionalItems,
            rate: await this.getRate(this.userData.country)
        }
    }

    async getRate(companyCountry: string) {
        if (companyCountry === 'Беларусь') {
            const res = await axios.create().get('https://www.nbrb.by/api/exrates/rates/292')
            console.log(res.data.Cur_OfficialRate)

            return Math.ceil((res.data.Cur_OfficialRate * 1.02)*100)/100

        } else if (companyCountry === 'Россия') {
            const res = await axios.create().get('https://www.cbr-xml-daily.ru/daily_json.js')
            console.log(res.data.Valute.EUR.Value)

            return Math.ceil((res.data.Valute.EUR.Value * 1.02)*100)/100

        } else if (companyCountry === 'Казахстан') {
            if (this.userData.role === 'Dealer'){
                return 1
            } else {
                const res = await axios.create().get('https://free.currencyconverterapi.com/api/v5/convert?q=EUR_KZT&compact=ultra&apiKey=737080e8b43cba5bccd0')
                console.log(res.data.EUR_KZT)

                return Math.ceil((res.data.EUR_KZT * 1.02)*100)/100
            }
        }
    }

}